/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import Input from "../components/FormElements/Input"
import * as styles from "../styles/spalsh"
import {detallesNodo } from "../services/api"
import Loader from "../components/loader";

const Splash = () => {

    /* Hooks */
    const navigate = useNavigate()
    const [datos, setDatos] = useState(null)
    const [loading, setLoading] = useState(false)
    const [msjAlerta, setMsjAlerta] = useState("")

    useEffect(() => {
        setLoading(true);
        localStorage.clear();
        let url = window.location.href;
        if((url).includes("nodo1")){
            localStorage.setItem('colorNodo',"#3B6CB1");
            localStorage.setItem('nodo',"1");
            document.querySelector(':root').style.setProperty('--principal-color', '#3B6CB1')
        }else if ((url).includes("nodo2")){
          localStorage.setItem('colorNodo',"#006240");
            localStorage.setItem('nodo',"2");
            document.querySelector(':root').style.setProperty('--principal-color', '#006240')
        }else if ((url).includes("nodo3")){
          localStorage.setItem('colorNodo',"#00874D");
            localStorage.setItem('nodo',"3");  
            document.querySelector(':root').style.setProperty('--principal-color', '#00874D')       
        }else{
          localStorage.setItem('colorNodo',"#006240");
          localStorage.setItem('nodo',"2");
          document.querySelector(':root').style.setProperty('--principal-color', '#006240'); 
        }
        console.log("entre inicio: ", localStorage.getItem('nodo'))
        // console.log("Version 1.26.10");
        detallesNodo().then((response) => {
            console.log("response:",response.data);
            setDatos(response.data)
            setTimeout(() => {
                navigate('/login')
            }, 4000)
        }).catch((e) => {
            console.log("e",e);
            setMsjAlerta("Ha ocurrido un error, por favor revisa tu conexión a internet")
        }).finally(() => {
            setLoading(false)
        })
        
    }, [])

    return (
    
        <>
            {
                datos?
                <div className={styles.main_container}>
                    <div className={styles.circle_container}>
                        <div className={styles.circle}>
                            <img className={styles.logo} src={datos.url_image} width={560} height={108} alt=""/>
                        </div>
                    </div>
                </div>
                :
                loading?
                <div className={styles.loaderModificaciones}>
                <Loader/>
                </div>
                : null
            }
            <Alert key={"alerta"} variant={"danger"} hidden={!msjAlerta} >
                {msjAlerta}
            </Alert>
        </> 
        
    )
}

export default Splash