import * as styles from "../../styles/input_label";

export default function InputLabel({
  children,
  id,
  ...props
}) {
  return (
    <label htmlFor={id} className={styles.label(props)}>
      {children}
    </label>
  );
}
