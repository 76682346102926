import {React, useEffect} from "react";
/* hooks */
import useValidateIdentity from "../../logic/hooks/useValidateIdentity";
/* Components */
import Input from "../FormElements/Input";
import InputFile from "../FormElements/InputFile";
import Alertas from '../../components/Sharing/Alert';
/* styles */
import * as styles from "../../styles/validate_identity";
import { useNavigate } from "react-router-dom";

export default function Form() {
  
  const navigate = useNavigate();
  const {
    formikSubmit,
    getFieldProps,
    touched,
    errors,
    handleFileChange,
    selfieName,
    end,
    curp,
    msjAlerta,
    loader
  } = useValidateIdentity();

  useEffect(() => {
    if (end) {
      setTimeout(() => {
        navigate('/home')
      }, 1000);
    }
  }, [end])

  const regresarHome = () => {
    navigate('/home')
  }
  
  return (
    <form  className={styles.form} onSubmit={formikSubmit}>
      <Input
        id="curp"
        type="text"
        label="CURP"
        error={errors.curp}
        // touched={touched.curp}
        {...getFieldProps("curp")}
      />
      <InputFile
        id="selfie"
        label="Subir selfie"
        error={errors.selfie}
        accept={"image/*"}
        handleFileChange={handleFileChange}
        inputValue={selfieName}
        {...getFieldProps("selfie")}
      />
      <div className="d-flex gap-3 mt-5 justify-content-start container-fluid p-0">
        <button 
          type="submit" 
          className={`btn ${!(getFieldProps('curp')?.value && getFieldProps('selfie')?.value) ? 'btn-secondary' : 'btn-primary'} p-2`}
          disabled={!(getFieldProps('curp')?.value && getFieldProps('selfie')?.value)}
        >
          Validar identidad
        </button>
        <button type="button" onClick={regresarHome} className="btn bg-transparent ">
          Cancelar
        </button>
      </div>
      {
          (end)? <Alertas type={"success"} children={"La validación fue exitosa"}></Alertas> : ""
      }
      {
        (msjAlerta)? <Alertas type={"error"} children={msjAlerta}></Alertas> : ""
      }
    </form>
  );
}
