import React, { useEffect, useState, useMemo } from "react";
/* Librerías */
import { Modal } from "react-bootstrap";
/* Componentes */
import TablePagination from "../DataTable/Pagination/index";
import TableHeader from "../DataTable/Header/index";
import Loader from "../loader";
import Alertas from "../Sharing/Alert";
import { table } from "../../logic/data/search_result/table";
/* Servicios */
import { busquedaCurp, obtenerStatus, obtenerTransacciones, obtenerTransaccionesPaginado, obtenerImagenConsulta } from "../../services/api";
/* Styles */
import * as styles from "../../styles/search_results";
import * as stylesIcon from "../../styles/validate_identity";

export default function Table({ start_date, end_date, searchCurp, newSearch }) {
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [curp, setCurp] = useState("");
  const [datos, setDatos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const ITEMS_PER_PAGE = 7;
  const [datosConsenso, setDatosConsenso] = useState([]);
  const [imagenC, setImagenC] = useState("");
  const [isModalImagen, setIsModalImagen] = useState(false);
  const [animationModal, setAnimationModal] = useState('animate__fadeInUp');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [prevStartDate, setPrevStartDate] = useState(null);
  const [prevEndDate, setPrevEndDate] = useState(null);
  const handleCloseMI = () => {
    setIsModalImagen(false);
    console.log("cerrando");
    listaPaginado(currentPage);
    /*
    if (!startDate) {
        setCurp(new URL(window.location).searchParams.get("curp"))
        mostrarLista()
    } else {
        filtroRegistro()
    }
    */
  };

  useEffect(() => {
    setLoading(true);
    if (new URL(window.location).searchParams.get("curp")) {
      setCurp(new URL(window.location).searchParams.get("curp"));
      mostrarLista();
    } else {
      listaTransaction();
    }
  }, []);

  useEffect(() => {
    if (start_date) setStartDate(start_date);
    if (end_date) setEndDate(end_date);
  }, [start_date, end_date]);

  useEffect(() => {
    if (searchCurp) {
        setCurp(searchCurp);
        mostrarLista(searchCurp);
    };
  }, [newSearch]);

  useEffect(() => {
    if (startDate !== prevStartDate || endDate !== prevEndDate) {
      if (startDate && endDate && startDate <= endDate) {
        console.log("ambas cambiaron y concuerdan para un rango");
        listaTransactionByDate();
        setPrevStartDate(startDate);
        setPrevEndDate(endDate);
      }
    }
  }, [startDate, endDate]);


const listaTransactionByDate = () => {
    setLoading(true);
    obtenerTransacciones(ITEMS_PER_PAGE, startDate, endDate, curp)
      .then((response) => {
        console.log("response:", response.data);

        //setTotalItems(response.data.total_records)
        setDatos(
          parseInt(response.data.total_records) === 0
            ? []
            : response.data.transactions
        );
        setCompleted(true)
      })
      .catch((e) => {
        console.log("e", e);
        /*
      if(e.response.data.error.description){
          setMsjAlerta(e.response.data.error.description)
      }else{
          setMsjAlerta("Ha ocurrido un error, por favor revisa tu conexión a internet")
      }
      */
        //setMsjAlerta("Ha ocurrido un error, por favor revisa tu conexión a internet")
      })
      .finally(() => {
        setLoading(false);
      });
  }
  
  const mostrarLista = (searchCurp) => {
    let curp = searchCurp ?? new URL(window.location).searchParams.get("curp");
    setDatos([])
    busquedaCurp(curp, currentPage).then((response) => {
      console.log("responseT", response.data.transactions);
      if (Object.keys(response.data.transactions[0]).length > 0) {
        setTotalItems(response.data.total_records)
        setDatos(response.data.transactions)
        setCompleted(true)
      } else {
        console.log("no hay transactions");
      }
    }).catch((e) => {
      console.log("e", e);
      //setTituloModal("Error")
      if (e.response.data.error.description) {
        //setRespuestaModal(e.response.data.error.description)
      } else {
        //setRespuestaModal("Revisa tu conexión a internet")
      }
    }).finally(() => {
      //console.log("finished");
      setLoading(false)
      //setShow(true)
    })
  }

  const listaTransaction = () => {
    setLoading(true)
    obtenerTransacciones(ITEMS_PER_PAGE, 0, 0).then((response) => {
      console.log(`🚀 ----------------------------------------------🚀`)
      console.log(`🚀 ~ obtenerTransacciones ~ response:`, response)
      console.log(`🚀 ----------------------------------------------🚀`)
      setTotalItems(response.data.total_records)
      setDatos(response.data.transactions)
      setCompleted(true)
    }).catch((e) => {
      console.log("e", e);
      /*
      if(e.response.data.error.description){
          setMsjAlerta(e.response.data.error.description)
      }else{
          setMsjAlerta("Ha ocurrido un error, por favor revisa tu conexión a internet")
      }
      */
        //setMsjAlerta("Ha ocurrido un error, por favor revisa tu conexión a internet")
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const listaPaginado = (page) => {
    setLoading(true)
    obtenerTransaccionesPaginado(ITEMS_PER_PAGE, startDate, endDate, curp, page).then((response) => {
      console.log(`🚀 ---------------------------------------🚀`)
      console.log(`🚀 ~ listaPaginado ~ response:`, response)
      console.log(`🚀 ---------------------------------------🚀`)
      setTotalItems(response.data.total_records)
      setDatos(response.data.transactions)
      setCompleted(true)
    }).catch((e) => {
      console.log("e", e);
      //setMsjAlerta("Ha ocurrido un error, por favor revisa tu conexión a internet")
    }).finally(() => {
      setLoading(false)
    })
  }

  const abrirBlockChain = (hash) => {
    //const hostNodes = ["","http://node1.devdicio.net:8080", "http://nodeaws.devdicio.net:8080","http://nodegcp.devdicio.net:8080"]
    const hostNodes = [
      "",
      process.env.REACT_APP_NODEBC1,
      process.env.REACT_APP_NODEBC2,
      process.env.REACT_APP_NODEBC3,
    ];
    let url = hostNodes[localStorage.getItem("nodo")] + "/tx/" + hash;
    window.open(url, "_blank").focus();
  };

  const getFecha = (date_created) => {
    const f = new Date(date_created);
    let mes = f.getMonth() + 1;
    if (date_created) {
      let fecha =
        f.getDate() +
        " - " +
        mes +
        " - " +
        f.getFullYear() +
        "  " +
        f.getHours() +
        ":" +
        f.getMinutes();
      return fecha;
    } else {
      return "--";
    }
  };

  const verConsenso = (trx, filename) => {
    setLoading(true);
    console.log("filename", filename);

    let promiseData = obtenerStatus(trx, 0);
    let promise0 = obtenerImagenConsulta(trx, filename, 0);
    Promise.allSettled([promiseData, promise0]).then(
      (response) => {
        console.log("response", response);
        console.log("data", Object.keys(response[0].value.data).length);

        setDatosConsenso(response[0].value.data);
        console.log("setDatosConsenso", response[0].value.data);

        let lista = [];
        if (response[1].status === "rejected") {
          setImagenC(
            "/images/admin_img/hacker_2.svg"
          );
        } else {
          /*setImagenC(
            "data:" +
              response[1].value.data.mime_type +
              ";base64," +
              response[1].value.data.image_base64
          );*/
          setImagenC("/images/admin_img/hacker.svg")
        }
        setTimeout(() => {
          setIsModalImagen(true);
          setLoading(false);
        }, 500);
      },
      (reason) => {
        console.log("reason", reason);
        setLoading(false);
      }
    );
  };

  const getTiempo = (d1, d2) => {
    let uno = new Date(d1);
    let dos = new Date(d2);
    let tiempo = dos.getTime() - uno.getTime();
    //console.log("tiempo:",tiempo);
    if (isNaN(tiempo)) return "--";
    else return (tiempo / 1000).toFixed(4);
  };

  const obtenerDatoOrden = (indice, json) => {
    //console.log("index",(indice+1));
    let newArray = {};
    if (json) {
      //datos.sort(((a, b) => a.id_node - b.id_node));
      let datos = json.matchnode.sort(function (a, b) {
        var x = a["id_node"],
          y = b["id_node"];
        return x < y ? -1 : x > y ? 1 : 0;
      });
      for (let index = 0; index < datos.length; index++) {
        //console.log("buscando en nodo", index);
        const element = datos[index];
        if (element.id_node === "node_blockchain_" + (indice + 1)) {
          //console.log("element.id_node",element.id_node);
          newArray.exists = element.exists;
          newArray.liveness = element.liveness;
          newArray.match = element.match;
          break;
        }
      }
      console.log("newArray", newArray.exists);
      if (newArray.exists === 0 || newArray.exists === 1) {
        return (
          <tbody>
            {
              (newArray.exists === 1) ?
                <tr key="match">
                  <th style={{paddingRight:"40px"}}>Match:</th>
                  <th>{newArray.match === 1 ? <span className="" style={{ color: "green", fontSize: "medium" }}> <i className="bi bi-check-circle"></i></span> : <span className="" style={{ color: "#f4bc39", fontSize: "medium" }}> <i className="bi bi-exclamation-circle"></i></span>}</th>
                </tr>
                : ""
            }
            {
              (newArray.exists === 1) ?
                <tr key="liveness">
                  <th style={{paddingRight:"40px"}}>Liveness:</th>
                  <th>{newArray.liveness === 1 ? <span className="" style={{ color: "green", fontSize: "medium" }}> <i className="bi bi-check-circle"></i></span> : <span className="" style={{ color: "#f4bc39", fontSize: "medium" }}> <i className="bi bi-exclamation-circle"></i></span>}</th>
                </tr>
                : ""
            }
            <tr key="curp">
              <th style={{paddingRight:"40px"}}>Curp:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th>{newArray.exists === 1 ? <span className="" style={{ color: "green", fontSize: "medium" }}> <i className="bi bi-check-circle"></i></span> : <span className="" style={{ color: "red", fontSize: "medium" }}> <i className="bi bi-x-circle"></i></span>}</th>
            </tr>
          </tbody>
        );
      } else {
        return (
          <p>
            <b>Estatus:</b> No se realizo consulta
            <br />
          </p>
        );
      }
    } else {
      console.log("error");
    }
  };

  const closeModal = () => {
    setAnimationModal('animate__fadeOutDown')
    setTimeout(() => {
      setIsModalImagen(false)
      setTimeout(() => {
        setAnimationModal('animate__fadeInUp')
      }, 100);
    }, 400);
  }

  return (
    <div
      className={`animate__animated animate__fadeInUp animate__delay-2s animate__slow container-fluid d-flex flex-column justify-content-start bg-white gap-3 p-0 ${styles.table_container}`}
      style={{ overflowY: "auto" }}
    >
      <TableHeader
        headers={table.header}
        onSorting={(data) => console.log(data)}
      />
      <tbody className="body_table_animation">
        {datos &&
          datos.map(
            (
              {
                id_node,
                transaction_code,
                curp,
                hash_blockchain,
                date_created,
                result_text,
                status_transaction,
                image_name,
              },
              index
            ) => (
              <tr key={index}>
                <td>{transaction_code.split("-")[0]}</td>
                <td>{curp}</td>
                <td>
                  {hash_blockchain !== null ? (
                    status_transaction !== "ERROR" ||
                    status_transaction !== "INCOMPLETED" ? (
                      <td>
                        {hash_blockchain.substr(0, 4)}...
                        {hash_blockchain.substr(hash_blockchain.length - 5)}
                      </td>
                    ) : (
                      <td>
                        <span className="badge rounded-pill bg-secondary">
                          {" "}
                          <i className="bi bi-three-dots"></i>
                        </span>
                      </td>
                    )
                  ) : status_transaction !== "ERROR" &&
                    status_transaction !== "INCOMPLETED" ? (
                    <td>
                      <i className="bi bi-clock-history"></i>
                    </td>
                  ) : (
                    <td>
                      <span className="badge rounded-pill bg-secondary">
                        {" "}
                        <i className="bi bi-three-dots"></i>
                      </span>
                    </td>
                  )}
                </td>
                <td>{getFecha(date_created)}</td>
                <td>
                  <p
                    className={`${styles.status_label(
                      result_text
                    )} p-1 rounded m-0`}
                  >
                    {result_text === "green"
                      ? "EXITOSO"
                      : result_text === "red"
                      ? "NO EXITOSO"
                      : "EN PROCESO"}
                  </p>
                </td>
                <td className="d-flex gap-2">
                  <button
                    type="button"
                    className={`btn btn-primary ${styles.btn_detalle}`}
                    onClick={(e) => {
                      verConsenso(transaction_code, image_name, id_node);
                    }}
                  >
                    Ver detalle
                  </button>
                  <button
                    type="button"
                    className={`btn btn-primary ${styles.btn_detalle}`}
                    onClick={(e) => {
                      abrirBlockChain(hash_blockchain);
                    }}
                    disabled={
                      status_transaction !== "ERROR" ||
                      status_transaction !== "INCOMPLETED" ||
                      !status_transaction
                        ? false
                        : true
                    }
                  >
                    Ver atestación
                  </button>
                </td>
              </tr>
            )
          )}
      </tbody>
      <TablePagination
        total={totalItems}
        itemsPerPage={ITEMS_PER_PAGE}
        currentPage={currentPage}
        className="justify-content-center animate__animated animate__fadeInUp animate__delay-5s animate__slow "
        onPageChange={page =>{setCurrentPage(page);listaPaginado(page);}}
      />

      <Modal className={`animate__animated ${animationModal}`} show={isModalImagen} onHide={handleCloseMI} size="lg" backdrop="static">
        <Modal.Header>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            {/* <i className="bi bi-x-lg close_modal_icon" onClick={closeModal}/> */}
      <div className={stylesIcon.contentHeaderSalir} ><button onClick={closeModal}><img src="/images/admin_img/tache_superior.svg" alt='' /></button></div>
            <div className="row">
              <div className="col-sm-3 centrarDiv">
                <div
                  className="cover_bgx"
                  style={{
                    width: "150px",
                    height: "150px",
                    backgroundImage: `url(${imagenC})`,
                  }}
                ></div>
              </div>
              <div className="col-sm-9 p-5">
                <div className="row">
                  <h2 style={{ color: "#22135E" }}>Resultado</h2>
                  <div className="col-sm-7">
                    {
                      (Object.keys(datosConsenso).length > 0) ?
                        (Object.keys(datosConsenso.errors[0]).length === 0) ?
                          <table>
                            <tr style={{ height: "25px" }}>
                              <td style={{width:"100%"}}><b>Nombre completo:</b></td> <td>{datosConsenso.client?.names + " " + datosConsenso.client?.paternal_surname + " " + datosConsenso.client?.mothers_maiden_name}</td>
                            </tr>
                            <tr style={{ height: "25px" }}>
                              <td><b>CURP:</b></td> <td>{datosConsenso.query.curp}</td>
                            </tr>
                            <tr style={{ height: "25px" }}>
                              <td><b>Comparación biométrica:</b></td><td>{(datosConsenso.query.status_transaction === "TERMINATED") ? "Terminada" : "En proceso"}</td>
                            </tr>
                          </table>
                          :
                          <div>
                            <p>
                              <b>Descripción del error:</b><br />{datosConsenso.errors[0].description_error} <br />
                              <b>Fecha de consulta:</b> <br />{getFecha(datosConsenso.errors[0].date_created)}<br />
                            </p>
                          </div>

                        :
                        <div></div>
                    }
                  </div>
                  <div className="col-sm-5">
                    {Object.keys(datosConsenso).length > 0 ? (
                      Object.keys(datosConsenso.errors[0]).length === 0 ? (
                        <div>
                          <p>
                            <b>Fecha de consulta:</b>
                            <br /> {getFecha(datosConsenso.query.date_created)}
                            <br />
                            <br />
                            <b>Tiempo de consulta:</b>
                            <br />{" "}
                            {getTiempo(
                              datosConsenso.query.date_created,
                              datosConsenso.score.date_created
                            )}{" "}
                            seg
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p></p>
                        </div>
                      )
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Body style={{ marginTop: "2%", padding: "0" }}>

          <table className="table">
            <thead style={{ background: "#22135E", color: "white" }}>
              <tr key="table-2">
                <th scope="col" style={{ textAlign: "center" }}>Nodo</th>
                <th scope="col" style={{ textAlign: "center" }}>Resultado</th>
              </tr>
            </thead>
            <tbody>
              {
                (Object.keys(datosConsenso).length > 0) ?
                  datosConsenso.matchnode.map((item, index) => (
                    <tr key={"subtabla-" + index} style={{ height: "100px" }}>
                      <th scope="row" className="nodoImg">
                        <div >
                          <img src={datosConsenso.matchnode[index].logo_node}></img>
                        </div>
                      </th>
                      <td style={{display:"flex", justifyContent:"center"}}>
                        {
                          (datosConsenso.matchnode[index]) ?
                            <table style={{marginRight:"15px", height: "100px" }}>
                              {obtenerDatoOrden(index, datosConsenso)}
                            </table>
                            :
                            <p>
                              <b>Estatus:</b> No se realizo consulta<br />
                            </p>
                        }
                      </td>
                    </tr>
                  ))
                  : <p>No hay datos</p>
              }
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      {
        (completed) ? 
          (datos.length === 0 && curp) ? 
            <Alertas type={"error"} children={"No se encontraron registros"}></Alertas> : ""
          :
          ""
      }
      {loading ? <Loader /> : ""}
    </div>
  );
}
