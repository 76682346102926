import { css, cx } from "@emotion/css";
import { font } from "../logic/utils/styles/mixins";
export const alert = cx(
  font({
    size: "16px",
    weight: 700
  }),
  css`
  width: 90%;
  padding: 10px 15px;
  border-radius: 3px;
  position: fixed !important;
  top: 13%;
  left: 54%;
  transform: translateX(-50%);
  z-index: 999999;
  color: white;
`);

export const alert_colors = {
  "error": css`
    background: #EE3737;
    border: 1px solid #FF0000;
  `,
  "success": css`
    background: #04B400;
    border: 1px solid #63EC6A;
  `
}