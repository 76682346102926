import React, { useEffect } from "react";
import Input from "../FormElements/Input";
import useLogin from "../../logic/hooks/useLogin";
import * as styles from "../../styles/login";
import Alertas from "../Sharing/Alert";

export default function Form() {

  const {
    formikSubmit,
    getFieldProps,
    handleStepInput,
    touched,
    errors,
    errorLogin,
    passwordStep,
  } = useLogin();

  return (
    <form
      className={`${styles.form} p-0 overflow-hidden`}
      onSubmit={formikSubmit}
    >
      <div
        className={`row m-0 ${styles.step_container(passwordStep)}`}
        style={{ width: "200%" }}
      >
        <div className="col p-0  d-flex flex-column ">
          <p className="fw-bold">Captura tus datos para ingresar al panel de control</p>
          <Input
            id="email"
            type="text"
            label="Correo electrónico"
            error={errors.email}
            // touched={touched.email}
            {...getFieldProps("email")} 
          />
          <button
            disabled={!(touched.email && !errors.email)}
            onClick={handleStepInput}
            className={`btn ${
              !(touched.email && !errors.email)
                ? "btn-secondary"
                : "btn-primary"
            } fw-bold mt-2 ${styles.button_steps}`}
            type="button"
          >
            Siguiente
          </button>
        </div>
        <div className="col p-0  d-flex flex-column ">
          <p className="fw-bold">
            {errorLogin ? errorLogin : "Hola, captura tu contraseña para ingresar"}
          </p>

          <Input
            id="password"
            type="password"
            label="Contraseña"
            error={errors.password || errorLogin}
            touched={touched.password}
            {...getFieldProps("password")}
          />

          <button
            disabled={!(touched.password && !errors.password)}
            className={`btn ${
              !(touched.password && !errors.password)
                ? "btn-secondary"
                : "btn-primary"
            } fw-bold mt-2 ${styles.button_steps}`}
            type="submit"
          >
            {errorLogin ? "Volver a intentar" : "Ingresar"}
          </button>
        </div>
      </div>
    </form>
  );
}
