import { css, cx } from "@emotion/css";

export const page = css`
  min-height: 100vh;
`;

export const form_container = css`
  max-width: 500px;
  height: 100vh;
  max-height: 500px;
  border-radius: 50%;
`;

export const form = css`
  max-width: 279px;
`;

export const color_link = css`
  color: var(--accent-color);
`;

export const logo = css`
  max-width: 240px !important;
  width: 75%;
  height: auto;
`;

export const forgot_link = cx(
  color_link,
  css`
    font-size: 20px;
    &:hover {
      cursor: pointer;
    }
  `
);

export const link = cx(
  color_link,
  css`
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  `
);

export const step_container = (passwordStep) => css`
  transition: all 0.4s ease-in-out;
  transform: translateX(${passwordStep ? `-50%` : `0%`});
`;

export const button_steps = css`
  width: fit-content;
  font-size: 20px !important;
  padding: 10px 20px !important;
  margin-top: 40px !important;
  color: white !important;
`;

export const logo_alliance = css`
  position: absolute;
  top: 47px;
  left: 40px;
`;
