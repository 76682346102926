import React, { useState } from 'react';

export const ProgressBarContext = React.createContext();

export const ProgressBarProvider = ({ children }) => {
    const [loader, setLoader] = useState(false);
    const [valueProgressBar, setValueProgressBar] = useState([]);
    return (
        <ProgressBarContext.Provider value={{ loader, setLoader, valueProgressBar, setValueProgressBar }}>
            {children}
        </ProgressBarContext.Provider>
    );
};