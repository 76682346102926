import React from "react";
import LoginForm from "../components/Login/Form";
import LoginCard from "../components/Login/LoginCard";
import { page } from "../styles/login"

export default function Login() {

  localStorage.clear();
  let url = window.location.href
  if((url).includes("nodo1")){
      localStorage.setItem('colorNodo',"#3B6CB1");
      localStorage.setItem('nodo',"1");
      document.querySelector(':root').style.setProperty('--principal-color', '#3B6CB1')
  }else if ((url).includes("nodo2")){
    localStorage.setItem('colorNodo',"#006240");
      localStorage.setItem('nodo',"2");
      document.querySelector(':root').style.setProperty('--principal-color', '#006240')
  }else if ((url).includes("nodo3")){
    localStorage.setItem('colorNodo',"#00874D");
      localStorage.setItem('nodo',"3");  
      document.querySelector(':root').style.setProperty('--principal-color', '#00874D')       
  }else{
    localStorage.setItem('colorNodo',"#00874D");
      localStorage.setItem('nodo',"3");  
      document.querySelector(':root').style.setProperty('--principal-color', '#00874D')   
  }
  console.log("entre inicio: ", localStorage.getItem('nodo'));
  
  return (
    <div className={`${page} container-fluid d-flex justify-content-center p-3 align-items-center`}>
      <LoginCard>
        <LoginForm />
      </LoginCard>
    </div>
  );
}
