import { useFormik } from 'formik';
import { INITIAL_VALUES } from "../constants/login"
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { LoginSchema } from '../schemas/login';
/* Servicios */
import { initSesion } from '../../services/api';


const useLogin = (action_type) => {
  const [passwordStep, setPasswordStep] = useState(false);
  const [errorLogin, setErrorLogin] = useState('');
  const navigate = useNavigate();

  const loginFormik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      // setErrorLogin('')
      console.log(`🚀 ------------------------------🚀`)
      console.log(`🚀 ~ useLogin ~ values:`, values)
      console.log(`🚀 ------------------------------🚀`)
      const { email, password } = values;
      try {
        const response = await initSesion(email, password)
        console.log(`🚀 -----------------------------------🚀`)
        console.log(`🚀 ~ onSubmit: ~ response:`, response)
        console.log(`🚀 -----------------------------------🚀`)
        const { status, data: { payload: { token } } } = response
        console.log(`🚀 -----------------------------🚀`)
        console.log(`🚀 ~ onSubmit: ~ token:`, token)
        console.log(`🚀 -----------------------------🚀`)
        // sessionStorage.setItem('token', '550e8400-e29b-41d4-a716-446655440000');
        if (status === 200) {
          sessionStorage.setItem('token', token);
          setTimeout(() => {
            navigate('/home')
          }, 300);
        } else {
          setErrorLogin('Los accesos son incorrectos, por favor verifica tus credenciales')
        }
        navigate("/home");
      } catch (error) {
        if (error.response = 'los accesos son incorrectos, verifica tus credenciales') {
          setErrorLogin('Los accesos son incorrectos, por favor verifica tus credenciales')
        } else {
          setErrorLogin('Error al iniciar sesión, por favor intenta nuevamente')
        }
        console.log(`🚀 -----------------------------🚀`)
        console.log(`🚀 ~ onSubmit: ~ error:`, error)
        console.log(`🚀 -----------------------------🚀`)

      }
    },
  });

  const handleStepInput = () => {
    if (loginFormik.values.email !== "") {
      setPasswordStep(!passwordStep);
    }else{
      loginFormik.setFieldError("email","Error")
    }
    //setPasswordStep(!passwordStep);
  };

  return {
    formikSubmit: loginFormik.handleSubmit,
    getFieldProps: loginFormik.getFieldProps,
    handleStepInput,
    touched: loginFormik.touched,
    errors: loginFormik.errors,
    errorLogin: errorLogin,
    passwordStep
  }
};

export default useLogin;