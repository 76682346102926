import React, { useEffect, useState, useMemo } from "react";
import Table from "./Table";
import Input from "../FormElements/Input";
/* Styles */
import * as styles from "../../styles/search_results";
import * as stylesIcon from "../../styles/validate_identity";
import DatePickerInput from "../FormElements/DatePickerInput"
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Results() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchCurp, setSearchCurp] = useState("");
  const [newSearch, setNewSearch] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showBtns, setshowBtns] = useState(false);
  const [classAnim, setClassAnim] = useState(false);

  const handlerStartDate = ({value}) => {
    setStartDate(value);
  }

  const handlerEndDate = ({value}) => {
    setEndDate(value);
  }

  const handleSearchCurp = (e) => {
    setSearchCurp(e.target.value.toUpperCase());
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchParams({curp: searchCurp}, {replace: true});
    setNewSearch((prevState) => !prevState);
  };

  useEffect(() => {
    setTimeout(() => {
      setClassAnim(true)
    }, 400);
  }, []);

  return (
    <>
    {(classAnim) &&
    <div className={`anim_bottom_top ${styles.results_container}`}>
      <div
        className={`container-fluid d-flex flex-column justify-content-start bg-white rounded gap-3 p-3`}
      >
        <div className="animate__animated animate__fadeInUp animate__delay-2s animate__fast container-fluid d-flex gap-3" style={{alignItems: 'end'}}>
          <h4 className={`${styles.title} fw-bold`}>
            Resultados de la búsqueda
      <div className={stylesIcon.contentHeaderSalir} ><button onClick={() => navigate('/home')}><img src="/images/admin_img/tache_superior.svg" alt='' /></button></div>
          </h4>
          <button
            className={`${styles.back_button} btn bg-transparent`}
            onClick={(e) => {
              navigate("/home");
            }}
          >
            <i className="bi bi-chevron-right icon_right_info"></i>
            Regresar
          </button>
        </div>
        <div className="row animate__animated animate__fadeInUp animate__delay-3s">
          <div className="col-sm-3 centrarDiv">
            <button
              type="button"
              className={`${styles.new_search} btn btn-primary`}
              onClick={(e) => {
                setshowBtns(true);
              }}
            >
              Nueva búsqueda
            </button>
          </div>
          
          <div className="col-sm-3 d-flex justify-content-center align-items-center">
            {showBtns && 
            <form onSubmit={handleSubmit} className="container-fluid p-0 d-flex justify-content-center align-items-center">
              <input
                type="text"
                class="form-control"
                style={{ borderRadius: "50px", fontSize: "10px", padding: "8px 9px 8px 9px" }}
                onChange={handleSearchCurp}
                value={searchCurp}
                placeholder="CURP"
                aria-label="CURP"
                aria-describedby="basic-addon1"
              />
            </form>}
          </div>
          <div className="col-sm-3 centrarDiv">
            {showBtns && <DatePickerInput 
            handleSetValue={handlerStartDate}
            value={startDate}
          />}
          </div>
          <div className="col-sm-3 centrarDiv">
          {showBtns && <DatePickerInput 
            handleSetValue={handlerEndDate}
            value={endDate}
          />}
          </div>
        </div>
      </div>
      <Table start_date={startDate} end_date={endDate} searchCurp={searchCurp} newSearch={newSearch}/>
    </div>
  }</>
  );
}
