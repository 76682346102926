import React from 'react'
/* styles */
import * as styles from '../styles/progressbar'

const Progressbar = ({
    value = 0
}) => {
    return (
        <>
            <div className={`container ${styles.container}`}>
                <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: `${value}%` }} aria-valuenow={value} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        </>
    );
}

export default Progressbar;
