import React from "react";
import InputLabel from "./InputLabel";
import * as styles from "../../styles/input";

export default function InputFile({
  error,
  handleFileChange,
  touched,
  id,
  inputValue,
  accept,
  ...props
}) {
  const inputError = !!(props.value === "" && error);
  const inputValid = !error && props.value !== "";
  const labelProps = {
    id,
    focused: inputValue !== "",
    inputError,
    inputValid,
  };
  return (
    <div
      style={{ zIndex: 1 }}
      className="m-0 d-flex flex-column container-fluid p-0 position-relative"
    >
      <div className="mt-3 mb-1 container-fluid p-0 position-relative">
        {props.label && <InputLabel {...labelProps}>{props.label}</InputLabel>}
        <div
          {...props}
          className={`${styles.input} bg-transparent  
      ${
        inputError
          ? `border-danger error_input`
          : inputValid
          ? `border-success success_input`
          : `border-black`
      }`}
        >
          {inputValue}
        </div>
        <input
          placeholder=""
          className={styles.input_file}
          id={id}
          type="file"
          value={""}
          name={id}
          {...(accept ? { accept } : null)}
          onChange={(event) => handleFileChange(event)}
        />
        <img className="arrow_icon_input" src="../images/dicio/rightArrow.svg" alt="icon" />
      </div>
    </div>
  );
}
