import { css } from "@emotion/css";

export const input = css`
  display: block;
  width: 100%;
  min-height: 36px;
  padding: 0.475rem 0.75rem;
  font-size: 20px;
  line-height: 1.5;
  color: #000000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #000000;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out;
  &:focus-visible {
    outline: none;
  }
`;

export const input_file = css`
  width: 100%;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const label = ({ focused, error, valid }) => css`
  position: absolute;
  left: ${!focused ? `.75rem` : `.5rem`};
  top: ${!focused ? `50%` : `0%`};
  transition: all 0.3s ease-in-out;
  transform: ${!focused ? `translateY(-50%)` : `translateY(-110%)`};
  pointer-events: none;
  color: #000;
  ${focused
    ? `font-size: 14px; font-weight: 400;`
    : `font-size: 20px; font-weight: bold;`}
  ${error && `color: var(--bs-form-invalid-border-color);`}
  ${valid && `color: var(--bs-form-valid-border-color);`}
`;
