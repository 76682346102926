import { css, cx } from "@emotion/css";
/* Mixins */
import { flex, content, grid } from "../logic/utils/styles/mixins";

const status = {
  "red": css`
    background: red;
    color: #FFF;
  `,
  "null": css`
    background: #F5B041;
    color: #FFF;
  `,
  "green": css`
    background: green;
    color: #FFF;
  `,
};
export const results_container = cx(
  flex({
    direction: "column",
    gap: "20px"
  }),
  css`
  width: 100%;
  max-width: none;
    padding: 0px 0px 0px 0px;
    max-height: 70%;
    min-height: 60%;
    align-self: center;
    justify-self: center;
    position: relative;
    // margin-left: 5%;
    padding-left: 7%;
    & .pagination{
      ${flex({
    gap: "10px"
  })}
      & li > a {
        border-radius: 50%;
        font-size: 20px;
        padding: 0;
        width: 30px;
        height: 30px;
        ${flex({})}
      }
      & li.disabled {
        opacity: .7;
        & a {
          color: gray;
        }
      }
    }
    
  `
);

export const title = cx(
  css`
    color: var(--numbers-color);
    font-size: 22px !important;
  `
);

export const new_search = cx(
  css` 
  width: fit-content;
    font-size: 14px !important;
    max-width: 145px;
    max-height: 23px;
    display: flex !important;
    align-items: center;
  `
);


export const back_button = cx(
  css` 
    width: fit-content;
    opacity: 0.7;
    font-size: 14px !important;
  `
);


export const btn_detalle = cx(
  css` 
  display: flex;
  align-items: center;
  height: 19px;
  background-color: var(--principal-color);
  width: 98px;
  display: flex;
  justify-content: center;
  `
);


export const status_label = (label_status) => cx(
  status[label_status],
  flex({}),
  css`
  display: flex;
  align-items: center;
  height: 19px;
    width: 80%;
    max-width: 85px;
    font-size: 10px!important;
    padding: 4px 10px 4px 10px !important;
    border-radius: 20px !important;
  `
);

export const table_container = cx(
  css` 
      border-radius: 8px;
      overflow: hidden;
    & thead {
      background: var(--accent-color);
    }
    & tr {
      margin-bottom: 10px;
      width: 100%;
      place-items: center;
      ${grid({
        template_cols: "1fr 2fr 2fr 2fr 2fr 3fr"
      })}
    }
    & th {
      color: white;
      font-size: 13px;
    }
    & td {
      font-size: 12px;
      width: 100%;
      ${flex({})}
    }
    & button {
      font-size: 10px !important;
    }
  `
);

/* DATA TABLE NOTIFICATIONS */
export const table_notifications_container = cx(
  css` 
      border-radius: 8px;
      overflow: hidden;
    & thead {
      background: var(--accent-color);
    }
    & tbody {
      margin-bottom: 15px;
    }
    & tr {
      width: 100%;
      place-items: start;
      align-items: center;
      ${grid({
        template_cols: "1fr 4fr 1fr"
      })}
      border-bottom: 0.5px #B8B8B8 solid;
      padding-block: 10px;
    }
    & th {
      color: white;
      padding: 5px 15px;
      font-size: 14px;
    }
    & td {
      ${flex({})}
      justify-content: start;
      padding-left: 15px;
      place-items: center;
      font-size: 14px;
      width: 100%;
      align-items: start;
    }
    & button {
      font-size: 9px !important;
    }
  `
);

/* DATA TABLE NOTIFICATIONS (transactions modal)*/
export const table_transactions_container = cx(
  css` 
      border-radius: 8px;
      overflow: hidden;
    & thead {
      background: var(--accent-color);
    }
    & tbody {
      margin-bottom: 10px;
    }
    & tr {
      width: 100%;
      place-items: start;
      align-items: center;
      ${grid({
        template_cols: "1fr 2fr 2fr 2fr 2fr"
      })}
      // border-bottom: 0.5px #B8B8B8 solid;
      padding-block: 10px;
    }
    & th {
      color: white;
      padding: 5px 15px;
      font-size: 14px;
      border-bottom: 0px !important;
    }
    & td {
      ${flex({})}
      justify-content: start;
      padding-left: 15px;
      place-items: center;
      font-size: 14px;
      width: 100%;
      align-items: start;
      border-bottom: 0px !important;
    }
    & button {
      font-size: 9px !important;
    }
  `
);