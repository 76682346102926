import React, { Fragment } from 'react';

const TooltipAlliance = ({contentTooltip, children, position}) => {

    /* Render */
    return (
        <Fragment>
            <span data-tooltip={contentTooltip} data-flow={position}>{children}</span>
        </Fragment>
    )
}

export default TooltipAlliance;