import { React, useEffect } from "react";
/* hooks */
import useRegistry from "../../logic/hooks/useRegistry";
/* Components */
import Input from "../FormElements/Input";
import InputFile from "../FormElements/InputFile";
import Loader from "../../components/loader"
/* styles */
import * as styles from "../../styles/validate_identity";
import { useNavigate } from "react-router-dom";
import Alertas from "../Sharing/Alert"

export default function Form() {

  const navigate = useNavigate();
  const {
    formikSubmit,
    getFieldProps,
    touched,
    errors,
    handleFileChange,
    selfieName,
    end,
    curp,
    msjAlerta,
    loader
  } = useRegistry();

  useEffect(() => {
    if (end) {
      setTimeout(() => {
        navigate('/home')
      }, 1000);
    }
  }, [end])

  const regresarHome = () => {
    navigate('/home')
  }

  useEffect(() => {
    
  console.warn(`✨ ------------------------------------------------------------✨`)
  console.warn(`✨ ~ file: Form.jsx:44 ~ Form ~ getFieldProps:`, getFieldProps('curp'), ' -- ', getFieldProps('selfie'))
  console.warn(`✨ ------------------------------------------------------------✨`)
  },[getFieldProps])

  return (
    <>
      <form className={styles.form} onSubmit={formikSubmit}>
        <Input
          id="curp"
          type="text"
          label="CURP"
          error={errors.curp || msjAlerta}
          // touched={touched.curp}
          {...getFieldProps("curp")}
        />
        <InputFile
          id="selfie"
          label="Subir selfie"
          error={errors.selfie || msjAlerta}
          accept={"image/*"}
          handleFileChange={handleFileChange}
          inputValue={selfieName}
          {...getFieldProps("selfie")}
        />
        <div className="d-flex gap-3 mt-5 justify-content-start container-fluid p-0">
          <button
            type="submit"
            className={`btn ${!(getFieldProps('curp')?.value && getFieldProps('selfie')?.value) ? 'btn-secondary' : 'btn-primary'} p-2`}
            disabled={!(getFieldProps('curp')?.value && getFieldProps('selfie')?.value)}
          >
            Agregar registro
          </button>
          <button type="button" onClick={regresarHome} className="btn bg-transparent">
            Cancelar
          </button>
        </div>
        <div >
          {
            (end) ? <Alertas type={"success"} children={"El registro se ha guardado con éxito"}></Alertas> : ""
          }
          {
            (msjAlerta) ? <Alertas type={"error"} children={msjAlerta}></Alertas> : ""
          }
        </div>
      </form>
    </>

  );
}
