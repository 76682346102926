import React from "react";
/* Librerías */
import { useNavigate } from "react-router-dom";
/* Componentes */
import TooltipAlliance from './tooltip';
/* Imagenes */
import NotificationsIcon from '../assets/notifications.svg';

const Header = ({ route, notifications, iconNotifications, absolute }) => {
    const navigate = useNavigate()

    return (
        <header className={`navbar_container ${absolute ? 'navbar_container_absolute' : ''}`}>
            {<div
                src="images/dicio/alliance_white_logo.svg"
                className={`alliance_logo_header`}
                height="100"
                alt="Admin Alliance"
                onClick={route ? () => navigate(route) : () => console.warn('...')}
            />}
            {iconNotifications &&
                <TooltipAlliance 
                    contentTooltip={'Alertas +'}
                    position={'bottom'}
                >
                    <a href={"/notifications"} className={`${notifications ? 'red_shadow_circle' : 'primary_shadow_circle'} notifications_icon_container animate__animated animate__fadeInUp animate__delay-1s`}>
                        <img
                            src={NotificationsIcon}
                            className={`alliance_notifications_header`}
                            height="15"
                            alt="Notifications Alliance"
                            onClick={() => navigate('/notifications')}
                        />
                        {notifications && iconNotifications ? 
                            <div className="circle_status"></div>
                        : ''}
                    </a>
                </TooltipAlliance>
            }
        </header>
    )
}

export default Header
