import React, { useContext, useEffect, useState } from 'react'
/* context */
import { ProgressBarContext } from '../../logic/context/progressBarProvider'
/* components */
import Form from "./Form"
import Progressbar from '../progressbar'
/* Styles */
import * as styles from "../../styles/validate_identity";
import { useNavigate } from "react-router-dom";

export default function FormCard() {
  const { loader, valueProgressBar } = useContext(ProgressBarContext)
  const [classAnim, setClassAnim] = useState(false)
  const navigate = useNavigate();
  const regresarHome = () => {
    navigate('/home')
  }
  useEffect(() => {
    setTimeout(() => {
      setClassAnim(true)
    }, 400);
  }, [])

  useEffect(() => {
    console.log(`🚀 ------------------------------🚀`)
    console.log(`🚀 ~ FormCard ~ loader:`, loader)
    console.log(`🚀 ------------------------------🚀`)
  }, [loader]);

  return (
    <>{(classAnim) &&
      <div id='validate_indeti_contendor_dco' className={`anim_bottom_top ${styles.form_card}`}>
        <div className={styles.contentHeaderSalir} ><button onClick={regresarHome}><img src="/images/admin_img/tache_superior.svg" alt='' /></button></div>
        <div className={`animate__animated animate__fadeInUp animate__delay-1s animate__slow ${styles.form_card_container}`}>
          <h4 className={styles.title}>Validar identidad</h4>
          <p className={styles.text}>Ingresa la información para validar una identidad</p>
          <Form />
        </div>
        {loader && <Progressbar value={valueProgressBar} />}
      </div>
    }</>
  )
}
