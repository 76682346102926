import { createTheme } from "@mui/material/styles";
import dayjs from "dayjs";

const useDatePicker = () => {
  const maxDate = dayjs();

  const handleValue = (value) => {
    return value !== "" ? dayjs(value) : null;
  };

  const handlerOfChanges = ({ value, handlerValue }) => {
    handlerValue({
      value:
        dayjs(value).format("DD/MM/YYYY") !== "Invalid Date"
          ? dayjs(value).toDate()
          : "",
    });
  };

  const newTheme = createTheme({
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            color: "#000000",
            borderRadius: "8px",
            border: "none",
            backgroundColor: "#FFFFFF",
          },
        },
      },
    },
  });
  return {
    handleValue,
    handlerOfChanges,
    newTheme,
    maxDate
  };
};

export default useDatePicker;
