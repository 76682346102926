import { css, keyframes, cx } from "@emotion/css";
import { flex } from "../logic/utils/styles/mixins";

export const fade_in = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

export const to_circle = keyframes`
  from {
    width: 200vw;
    height: calc(200vw);
  } to {
    width: 500px;
    height: 500px;
  }
`;

export const main_container = cx(
  flex({}),
  css`
    width: 100%;
    min-height: 100vh;
  `
);

export const circle_container = cx(
  flex({}),
  css`
    width: 100%;
    height: 100vh;
    position: relative;
  `
);

export const circle = cx(
  flex({}),
  css`
    width: 200vw;
    height: calc(200vw);
    border-radius: 50%;
    background: #fff;
    position: absolute;
    animation: ${to_circle} 1.5s ease-in-out 2s forwards;
  `
);

export const logo = cx(
  css`
    max-width: 60vw;
    width: 90%;
    height: auto;
    opacity: 0;
    max-width: 560px;
    max-height: 108px;
    animation: ${fade_in} 1s ease-in-out 1s forwards;
  `
);

export const loaderModificaciones = cx(
  css`
  width: 100dvw;
  height: 100dvh;
  background-color: white;
  & .loader_container{
    border-radius: 50%;
    background-color: #717171;
  }
  `
);
