import React, { useContext, useEffect, useState } from 'react'
/* context */
import { ProgressBarContext } from '../../logic/context/progressBarProvider'
/* components */
import Form from "./Form"
import Progressbar from '../progressbar'
/* Styles */
import * as styles from "../../styles/validate_identity"
import { useNavigate } from "react-router-dom";

export default function FormCard() {
  const { loader, valueProgressBar } = useContext(ProgressBarContext)
  const [classAnim, setClassAnim] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setClassAnim(true)
    }, 400)
  }, [])
  const regresarHome = () => {
    navigate('/home')
  }

  return (
    <>{(classAnim) &&
        <div id='validate_indeti_contendor_dco' className={`anim_bottom_top ${styles.form_card}`}>
          <div className={styles.contentHeaderSalir} ><button onClick={regresarHome}><img src="/images/admin_img/tache_superior.svg" alt='' /></button></div>
          <div className={`animate__animated animate__fadeInUp animate__delay-1s animate__slow ${styles.form_card_container}`}>
            <h4 className={styles.title}>Agrega un nuevo registro</h4>
            <p className={styles.text}>Ingresa la información para crear un  nuevo registro de identidad</p>
            <Form />
          </div>
          {loader && <Progressbar value={valueProgressBar} />}
        </div>
    }</>
  )
}
