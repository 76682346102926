import React from "react"


/* Imágenes */
import LogoBancoAzteca from "../../assets/01.svg"

/* Estilos */
import * as styles from "../../styles/login"

export default function LoginCard({children}) {

  return (
    <div
      className={`container-fluid bg-white d-flex flex-column justify-content-center p-3 p-md-4 p-lg-5 align-items-center ${styles.form_container}`}
    >
      <div 
       src={'../images/dicio/alliance_white_logo.svg'}
       alt="DICIO  Alliance logo"
       className={`mb-3 alliance_logo_header ${styles.logo_alliance}`}
       onClick={() => window.location.reload()}
      />
      <img
        src={localStorage.getItem('nodo') === "1" ? '../images/dicio/logo-bancoppel.svg' : localStorage.getItem('nodo') === "2" ? "../images/dicio/logo_az.png" : '../images/dicio/afirme.png'}
        alt="Banco Azteca"
        className={`mb-5 ${styles.logo}`}
      />
      {children}
    </div>
  );
}
