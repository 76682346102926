export const table = {
  header: [
    {
      name: "Folio",
      field: "id",
      sortable: false,
    },
    {
      name: "CURP",
      field: "curp",
      sortable: false,
    },
    {
      name: "Blockchain host",
      field: "blockchain",
      sortable: false,
    },
    {
      name: "Fecha y hora de consulta",
      field: "date",
      sortable: false,
    },
    {
      name:  "Status",
      field: "status",
      sortable: false,
    },
    {
      name: "Acciones",
      field: "actions",
      sortable: false,
    },
  ],
  body: [
    {
      id: "098707",
      curp: "PELJ800815HOCXXXXX",
      blockchain: "987b987tbgj",
      date: "12/21/2023",
      status: "NO EXITOSO",
    },
    {
      id: "97697698",
      curp: "GAHM950725MJCSRRXX",
      blockchain: "987b987tbgj",
      date: "12/21/2023",
      status: "EN PROCESO",
    },
    {
      id: "976987689",
      curp: "ROPJ770310HDFXXXXX",
      blockchain: "987b987tbgj",
      date: "12/21/2023",
      status: "EXITOSO",
    },
  ]
}

export const tableNotifications = {
  headers: [
    {
      name: "ID",
      field: "id",
      sortable: true,
    },
    {
      name: "Alerta",
      field: "alerta",
      sortable: false,
    },
    {
      name: "Acciones",
      field: "acciones",
      sortable: false,
    },
  ],
  body: [
    {
      id: "0000001",
      description: "El registro con la CURP HHRO234598 en tu base de datos puede tener un problema de seguridad."
    },
    {
      id: "0000002",
      description: "El registro con la CURP HHRO234598 tiene información distinta a 3 nodos de la red."
    },
    {
      id: "0000003",
      description: "El registro con la CURP HHRO234598 en tu base de datos tiene la calidad más baja de la red."
    },
  ]
}