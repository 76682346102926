import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import Footer from "../components/footer"
import Header from "../components/header"
import Loader from "../components/loader"
import {guardarRegistro} from "../services/api"


const Edit = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [curp, setCurp] = useState("");
    const [foto, setFoto] = useState("");
    const [show, setShow] = useState(false);
    const [c1, setC1] = useState(false);
    const [c2, setC2] = useState(false);
    const [c3, setC3] = useState(false);
    const [tituloModal, setTituloModal] = useState("");
    const [respuestaModal, setRespuestaModal] = useState("");
    const handleClose = () => setShow(false);

    useEffect(() => {
        if(localStorage.getItem('colorNodo'))
            document.querySelector(':root').style.setProperty('--principal-color', localStorage.getItem('colorNodo')); 
    }, []);

    const guardar = (event) => {
        if(event) event.preventDefault();
        const file = document.getElementById("file").files[0];
        console.log("file",file);
        if(file && curp){
            setLoading(true)
            let array = [c1,c2,c3];
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                if(element){
                    guardarRegistro(curp, file,index).then((response) => {
                        console.log("response"+index,response);
                        setTituloModal("Listo")
                        setRespuestaModal("Se ha guadado con éxito")
                    }).catch((e) => {
                        console.log("e",e);
                        setTituloModal("Error")
                        setRespuestaModal("Revisa tu conexión a internet")
                    }).finally(() => {
                        setShow(true)
                        setLoading(false)
                    })
                }
            }

        }else{
            console.log("no hay curp");
            setShow(true)
            setTituloModal("Error")
            setRespuestaModal("Por favor revisa que los datos esten completos")
        }
    }

    const gotoEditar = () => {
        setTimeout(() => {navigate('/home')}, 300)
    }

    return (
        <div className="">
            <Container className="py-4">
                {(loading) ? <Loader /> : ("")}
                <Header></Header>
                <Row>
                    <Col>
                        <h5><a href="/home" ><i className="bi bi-chevron-left"></i>Regresar</a></h5> <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5>Sube una <b>foto</b> y su <b>CURP</b> para agregar un registro:</h5> <br />
                    </Col>
                </Row>
                <Row className="justify-content-start">
                    <Col md={3}>
                        <Form.Check 
                            type={"checkbox"}
                            id={`default-1`}
                            label={`Subir nodo 1`}
                            onChange={e => setC1(e.target.checked)}
                        />
                    </Col>
                    <Col md={3}>
                        <Form.Check 
                            type={"checkbox"}
                            id={`default-2`}
                            label={`Subir nodo 2`}
                            onChange={e => setC2(e.target.checked)}
                        />
                    </Col>
                    <Col md={3}>
                        <Form.Check 
                            type={"checkbox"}
                            id={`default-3`}
                            label={`Subir nodo 3`}
                            onChange={e => setC3(e.target.checked)}
                        />
                    </Col>
                </Row>
                <br></br>
                <Row>
                    <Col md={4}>
                        <p>Selecciona la imagen a subir:</p>
                        <div className="input-group mb-3">
                            <input type="file" id="file" className="form-control" onChange={event => setFoto(event.target.value)}/>
                        </div>
                    </Col>
                    <Col md={4}>
                        <p>Ingresa el CURP:</p>
                        <input type="text" className="form-control" id="inputPassword2" placeholder="Ej: SOAL821123G64" onChange={event => setCurp(event.target.value)}/>
                    </Col>
                    <Col md={4}>
                        <p className="text-white">.</p>
                        <Button type="submit" className="btn btn-primary mb-3"  disabled={!(curp && foto && (c1 || c2 || c3))} onClick={event => guardar(event)}>Guardar Registro</Button>
                    </Col>
                </Row>

                <hr />
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{tituloModal}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>{respuestaModal}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Footer>
                </Footer>
            </Container>

        </div>
    )
}

export default Edit