import React, { useEffect, useState } from 'react';
/* Librerías */
import { Modal } from 'react-bootstrap';

const NotificationSmall = ({ showNotification, setShowNotification, handleCloseNotification, data }) => {
    const [animation, setAnimation] = useState('animate__fadeInUp')

    useEffect(() => {
        if (!showNotification) {
            setTimeout(() => {
                setAnimation('animate__fadeInUp');
            }, 500);
        }
    },[showNotification])

    const turnDownPopUp = () => {
        setAnimation('animate__fadeOutDown')
        setTimeout(() => {
            setShowNotification(false);
        }, 800);
    }

    /* Render */
    return (
        <Modal className={`animate__animated  black_background_modal ${animation}`} show={showNotification} onHide={handleCloseNotification ? handleCloseNotification : () => {}} size="lg" backdrop="static">
            <Modal.Header >
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <i className="bi bi-x-lg close_modal_icon" onClick={turnDownPopUp} />
                    <div className="row mb-3">
                        <div className="col-sm-3 centrarDiv">
                            <div
                                className={`cover_bgx_notifications ${data?.type_alert === 1 ? 'red_border' : 'yellow_border'}`}
                                style={{
                                    width: "125px",
                                    height: "125px",
                                }}
                            ></div>
                        </div>
                        <div className="col-sm-9">
                            <div className="row">
                                <h2 className='modal_title_alliance'>Alerta de inconsistencia</h2>
                                <div className="">
                                    <table>
                                        <tr style={{ height: "25px" }}>
                                            <td width={'20%'}><b>Nombre completo:</b></td> <td width={'40%'}>{data?.names + " " + data?.paternal_surname + " " + data?.mothers_maiden_name}</td>
                                            <td width={'20%'}><b>Fecha de consulta:</b></td> <td width={'20%'}>{data?.date_consulted}</td>
                                        </tr>
                                        <tr style={{ height: "25px" }}>
                                            <td><b>CURP:</b></td> <td>{data?.curp}</td>
                                        </tr>
                                        <tr style={{ height: "25px" }}>
                                            <td><b>Comparación biometrica:</b></td><td>{data?.status_transaction}<span className={`${data?.type_alert === 1 ? 'red_underline' : 'yellow_underline'}`}></span></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default NotificationSmall;