import { css, cx } from "@emotion/css";
import { media_query } from "../../constants/styles";

export function forsize(queries) {
  const devices = Object.keys(queries);
  const responsive = devices.map((key) =>
    media_query[key]
      ? css`
          @media screen and (${media_query[key]}) {
            ${queries[key]}
          }
        `
      : ""
  );
  return css`
    ${responsive}
  `;
}

export function forsizeProperties({ queries, css_propertie }) {
  const responsive = forsize({
    [queries]: css`
      ${css_propertie}
    `,
  });
  return css`
    ${responsive}
  `;
}

export const rText = ({ query, size }) => {
  const css_propertie = `font-size: ${size}px;`;
  return forsizeProperties({
    queries: query,
    css_propertie,
  });
};

export const btn = ({
  border,
  bg,
  color,
  padding,
  radius
}) => css`
  border: ${border ? `${border}`: `none`};
  background: ${bg ? `${bg}`: `var(--accent-color)`};
  color: ${color ? `${color}`: `white`};
  padding: ${padding ? `${padding}`: `10px 15px`};
  border-radius: ${radius ? `${radius}`: `4px`};
`;

export const font = ({ 
  size,
  color,
  weight,
  style,
  align,
  decoration
 }) => {
  return css`
  ${size && `font-size: ${size};`}
  ${color && `color: ${color};`}
  ${weight && `font-weight: ${weight};`}
  ${style && `font-style; ${style};`}
  ${align && `text-align: ${align};`}
  ${decoration && `text-decoration: ${decoration};`}
  `
};

export const flex = ({
  justify = "center",
  gap,
  align = "center",
  direction = "row",
}) => {
  const gapContent = gap ? `gap: ${gap};` : "";
  const alignContent = align ? `align-items: ${align};` : "";

  return css`
    display: flex;
    justify-content: ${justify};
    flex-direction: ${direction};
    ${gapContent}
    ${alignContent}
  `;
};

export const content = ({
  width = "2560px",
  padding = "60px 30px",
  margin = "0",
}) => css`
  display: block;
  width: 100%;
  max-width: ${width};
  margin: ${margin};
  padding: ${padding};
`;

export const grid = ({
  template_cols,
  template_rows,
  areas,
  auto_col,
  auto_rows,
  auto_flow,
  col_gap,
  row_gap,
  justify_items,
  align_tems,
  justify_content,
  align_content,
}) => css`
  display: grid;
  ${template_cols && `grid-template-columns: ${template_cols};`}
  ${template_rows && `grid-template-rows: ${template_rows};`}
  ${areas && `grid-template-areas: ${areas};`}
  ${auto_col && `grid-auto-columns: ${auto_col};`}
  ${auto_rows && `grid-auto-rows: ${auto_rows};`}
  ${auto_flow && `grid-auto-flow: ${auto_flow};`}
  ${col_gap && `grid-column-gap: ${col_gap};`}
  ${row_gap && `grid-row-gap: ${row_gap};`}
  ${justify_items && `justify-items: ${justify_items};`}
  ${align_tems && `align-items: ${align_tems};`}
  ${justify_content && `justify-content: ${justify_content};`}
  ${align_content && `align-content: ${align_content};`}
`;
