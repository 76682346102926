import React, { useEffect, useState } from "react";
import useInput from "../../logic/hooks/useInput";
import InputLabel from "./InputLabel";
import * as styles from "../../styles/input";

export default function Input({ error, touched, id, type, ...props }) {
  const {
    handleFocus,
    focused,
  } = useInput();
  const [isVisible, setIsVisible] = useState(false);
  const inputError = type !== "password" ? (!!(props.value === "" && error)) : !!(props.value !== "" && error);
  const inputValid = !error && props.value !== "";
  const labelProps = {
    id,
    focused: focused || props.value !== "",
    inputError,
    inputValid,
  };

  return (
    <div
      style={{ zIndex: 1 }}
      className="m-0 d-flex flex-column container-fluid p-0 position-relative"
    >
      <div className="mt-3 mb-1 container-fluid p-0 position-relative">
        {props.label && <InputLabel {...labelProps}>{props.label}</InputLabel>}
        <input
          {...props}
          className={`${styles.input} bg-transparent  
        ${inputError
              ? `border-danger error_input`
              : inputValid
                ? ``
                : `border-black`
            }`}
          type={type === "password" && isVisible ? "text" : type}
          onFocus={handleFocus}
          onChangeCapture={(e) => {
            handleFocus();
            props.onBlur(e);
          }}
        />
        {(type === "password" && isVisible && props.value !== "") ?
          <img onClick={() => setIsVisible(!isVisible)} className="eye_icon_input" src="../images/dicio/visible.svg" alt="icon" />
          : (type === "password" && !isVisible && props.value !== "") ?
            <img onClick={() => setIsVisible(!isVisible)} className="eye_icon_input" src="../images/dicio/noVisible.svg" alt="icon" />
            : ''
        }
      </div>
    </div>
  );
}
