import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider } from "@mui/material/styles";
import useDatePicker from "../../logic/hooks/useDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/es-mx";
import * as styles from "../../styles/datepicker";

export default function DatePickerInput({
  handleSetValue,
  value
}) {
  const {
    handleValue,
    handlerOfChanges,
    newTheme,
    maxDate,
  } = useDatePicker();

  return (
    <div className="p-0 position-relative" style={{width: "fit-content"}}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
        <ThemeProvider theme={newTheme}>
          <DatePicker
            className={`${styles.date_picker} border-black`}
            value={handleValue(value)}
            maxDate={maxDate}
            inputFormat="DD/MM/YYYY"
            onChange={(newValue) => {
              handlerOfChanges({
                value: newValue,
                handlerValue: handleSetValue,
              });
            }}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );
}
