import React from 'react';
/* Styles */
import * as styles from "../../styles/search_results";
import * as stylesIcon from "../../styles/validate_identity";
/* Librerías */
import { useNavigate } from 'react-router-dom';
/* Componentes */
import DataTable from './DataTable';

const NotificationsTable = () => {
    /* Hooks */
    const navigate = useNavigate()

    /* Render */
    return (
        <div className={`anim_bottom_top ${styles.results_container}`}>
            <div
                className={`container-fluid d-flex flex-column justify-content-start bg-white rounded gap-3 p-3`}
            >
            <div className={stylesIcon.contentHeaderSalir} ><button onClick={() => navigate('/home')}><img src="/images/admin_img/tache_superior.svg" alt='' /></button></div>
                <div className="animate__animated animate__fadeInUp animate__delay-2s animate__fast container-fluid d-flex gap-3" style={{ alignItems: 'end' }}>
                    <h3 className={`${styles.title} fw-bold`}>
                        Alertas
                    </h3>
                    <button
                        className={`${styles.back_button} btn bg-transparent`}
                        onClick={(e) => {
                            navigate("/home");
                        }}
                    >
                        <i className="bi bi-chevron-right icon_right_info"></i>
                        Regresar
                    </button>
                </div>
            </div>
            <DataTable />
        </div>
    )
}

export default NotificationsTable;