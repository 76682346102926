import React, { useEffect, useState } from 'react';
/* Librerías */
import { Modal } from "react-bootstrap";
/* Componentes */
import TableHeader from "../DataTable/Header/index";
import TablePagination from "../DataTable/Pagination/index";
/* Servicios */
import { busquedaCurp } from '../../services/api';
/* Styles */
import * as styles from "../../styles/search_results";
/* Styles */
import * as stylesIcon from "../../styles/validate_identity"

const NotificationComplete = ({ showNotification, setShowNotification, handleCloseNotification, data }) => {
    const [animation, setAnimation] = useState('animate__fadeInUp')
    const [transactions, setTransactions] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [totalItems, setTotalItems] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [date, setDate] = useState('')
    const headersNoActions = [
        {
            name: "Folio",
            field: "id",
            sortable: false,
        },
        {
            name: "CURP",
            field: "curp",
            sortable: false,
        },
        {
            name: "Blockchain host",
            field: "blockchain",
            sortable: false,
        },
        {
            name: "Fecha y hora de consulta",
            field: "date",
            sortable: false,
        },
        {
            name: "Status",
            field: "status",
            sortable: false,
        }
    ]

    useEffect(() => {
        if (!showNotification) {
            setTimeout(() => {
                setAnimation('animate__fadeInUp');
                setDate('')
                setTransactions([])
            }, 100);
        }
    }, [showNotification])

    const turnDownPopUp = () => {
        setAnimation('animate__fadeOutDown')
        if (handleCloseNotification)
            handleCloseNotification()
        setTimeout(() => {
            setShowNotification(false);
        }, 800);
    }

    useEffect(() => {
        if (data?.date_created) {
            let day = data.date_created.slice(8, 10)
            let month = data.date_created.slice(5, 7)
            let year = data.date_created.slice(0, 4)
            setDate(`${day} - ${month} - ${year}`)
        }
    }, [data])

    useEffect(() => {
        mostrarLista(data?.reference);
    }, [])

    /* Funciones */
    const mostrarLista = (searchCurp) => {
        busquedaCurp(searchCurp, currentPage).then((response) => {
            if (Object.keys(response.data.transactions[0]).length > 0) {
                setTransactions(response.data.transactions)
                setTotalItems(response.data.total_records)
            } else {
                console.log("No hay transacciones");
            }
        }).catch((e) => {
            console.warn(`✨ ------------------------------------------------------------✨`)
            console.warn(`✨ ~ file: NotificationComplete.jsx:52 ~ busquedaCurp ~ e:`, e)
            console.warn(`✨ ------------------------------------------------------------✨`)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const getFecha = (date_created) => {
        const f = new Date(date_created);
        let mes = f.getMonth() + 1;
        if (date_created) {
            let fecha =
                f.getDate() +
                " - " +
                mes +
                " - " +
                f.getFullYear() +
                "  " +
                f.getHours() +
                ":" +
                f.getMinutes();
            return fecha;
        } else {
            return "--";
        }
    };

    /* Render */
    return (
        <Modal className={`animate__animated black_background_modal ${animation}`} show={showNotification} size="lg" backdrop="static">
            <Modal.Header >
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className={stylesIcon.contentHeaderSalir} ><button onClick={turnDownPopUp}><img src="/images/admin_img/tache_superior.svg" alt='' /></button></div>
                    <div className="row mb-3">
                        <div className="col-sm-3 centrarDiv">
                            <div
                                className={`cover_bgx_notifications red_border`}
                                style={{
                                    width: "125px",
                                    height: "125px",
                                }}
                            ></div>
                        </div>
                        <div className="col-sm-9">
                            <div className="row">
                                <h2 className='modal_title_alliance'>Transacción en alerta</h2>
                                <div>
                                    <table className="table_notifications">
                                        <tr style={{ height: "25px" }}>
                                            <td width={'20%'}><b>CURP:</b></td> <td width={'40%'}>{data?.reference}</td>
                                            <td width={'20%'}><b>Fecha de consulta:</b></td> <td width={'20%'}>{date}</td>
                                        </tr>
                                        <tr style={{ height: "25px" }}>
                                            <td width={'20%'}><b>Comparación biometrica:</b></td><td width={'30%'}>Diferencia {data?.distance ? data.distance.toString().slice(0, 6) : '0'}%<span className={`red_underline`}></span></td>
                                        </tr>
                                        <tr style={{ position: 'absolute', width: '68%', marginTop: 5 }}>
                                            <td width={'20%'}><b>Alerta:</b></td><td width={'80%'}>{data.description}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Body style={{ marginTop: "2%", padding: "0" }}>

                <table className={`table ${styles.table_transactions_container}`}>
                    <TableHeader
                        hasAnimation={false}
                        headers={headersNoActions}
                        onSorting={(data) => console.log(data)}
                    />
                    {
                        (Object.keys(transactions).length > 0) ?
                                <tbody className="scroll_view">
                                    {transactions &&
                                        transactions.map(
                                            (
                                                {
                                                    id_node,
                                                    transaction_code,
                                                    curp,
                                                    hash_blockchain,
                                                    date_created,
                                                    result_text,
                                                    status_transaction,
                                                    image_name,
                                                },
                                                index
                                            ) => (
                                                <tr key={index}>
                                                    <td>{transaction_code.split("-")[0]}</td>
                                                    <td>{curp}</td>
                                                    <td>
                                                        {hash_blockchain !== null ? (
                                                            status_transaction !== "ERROR" ||
                                                                status_transaction !== "INCOMPLETED" ? (
                                                                <td>
                                                                    {hash_blockchain.substr(0, 4)}...
                                                                    {hash_blockchain.substr(hash_blockchain.length - 5)}
                                                                </td>
                                                            ) : (
                                                                <td>
                                                                    <span className="badge rounded-pill bg-secondary">
                                                                        {" "}
                                                                        <i className="bi bi-three-dots"></i>
                                                                    </span>
                                                                </td>
                                                            )
                                                        ) : status_transaction !== "ERROR" &&
                                                            status_transaction !== "INCOMPLETED" ? (
                                                            <td>
                                                                <i className="bi bi-clock-history"></i>
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                <span className="badge rounded-pill bg-secondary">
                                                                    {" "}
                                                                    <i className="bi bi-three-dots"></i>
                                                                </span>
                                                            </td>
                                                        )}
                                                    </td>
                                                    <td>{getFecha(date_created)}</td>
                                                    <td>
                                                        <p
                                                            className={`${styles.status_label(
                                                                result_text
                                                            )} p-1 rounded m-0`}
                                                        >
                                                            {result_text === "green"
                                                                ? "EXITOSO"
                                                                : result_text === "red"
                                                                    ? "NO EXITOSO"
                                                                    : "EN PROCESO"}
                                                        </p>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                </tbody>
                            :
                            <tbody style={{
                                alignItems: 'center', justifyContent: 'center',
                                display: 'flex', marginBlock: 10
                            }}>
                                <span>No hay datos</span>
                            </tbody>
                    }
                    <tfoot>
                    <TablePagination
                        total={totalItems}
                        itemsPerPage={10}
                        currentPage={currentPage}
                        onPageChange={page => { setCurrentPage(page); }}
                    />
                    </tfoot>
                </table>
            </Modal.Body>
        </Modal>
    )
}

export default NotificationComplete;