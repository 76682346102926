import React, {useEffect} from 'react'
/* Componentes */
import Logos from "../components/ValidateIdentity/Logos"
import FormCard from "../components/Search/FormCard"
import Header from '../components/header';
import Footer from '../components/footer';
/* Styles */
import * as styles from "../styles/validate_identity"
/* Provider */
import { ProgressBarProvider } from '../logic/context/progressBarProvider'
/* Images */
import SearchIcon from "../assets/search.svg"

export default function Search() {
  useEffect(() => {
    if(localStorage.getItem('colorNodo'))
        document.querySelector(':root').style.setProperty('--principal-color', localStorage.getItem('colorNodo')); 
}, []);
  return (
    <div className={styles.main_container}>
      <Header route={'/home'} absolute={true} />
      <Logos>
        <div className={`anim_icon_bottleft_center ${styles.user_circle}`}>
          <img src={SearchIcon} alt="Search icon" className={styles.logo} />
        </div>
      </Logos>
      <ProgressBarProvider>
        <FormCard />
      </ProgressBarProvider>
      <Footer position={'absolute'} />
    </div>
  )
}
