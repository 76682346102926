import { css } from "@emotion/css";
import Calendar from "../assets/calendar.webp"
export const date_picker = css`
  display: block;
  width: fit-content;
  padding: 8px 9px 8px 9px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  background-clip: padding-box;
  border-radius: 50px;
  transition: border-color .15s ease-in-out;
  box-shadow: none;
  border: 1px solid #D9D9D9 !important;
  &:focus-visible {
    outline: none;
  }
  & input {
    font-size: 14px !important;
    padding: 8px 9px 8px 9px !important;
  }
  & fieldset {
    border: none !important;
  }
  & button > svg {
    fill: none !important;
  }
  & button {
    position: relative;
    padding: 0;
    &::after{
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background: url(${Calendar}) no-repeat center/8px auto;
    }
  }
`;
