import React, {useEffect} from 'react';
/* Componentes */
import Logos from "../components/ValidateIdentity/Logos"
import Footer from '../components/footer'
import Header from '../components/header'
/* Styles */
import * as styles from "../styles/validate_identity"
/* Imagenes */
import NotificationsIcon from '../assets/notifications.svg'
import NotificationsTable from '../components/NotificationsForm/NotificationsTable';

const Notifications = () => {
    useEffect(() => {
        if(localStorage.getItem('colorNodo'))
            document.querySelector(':root').style.setProperty('--principal-color', localStorage.getItem('colorNodo')); 
    }, []);
    return (
        <div className={styles.main_container}>
            <Header route={'/home'} absolute={true} />
            <Logos>
                <div className={`anim_icon_topleft_center ${styles.user_circle}`}>
                    <img src={NotificationsIcon} alt="Register icon" className={`${styles.logo}`} />
                </div>
            </Logos>
            <NotificationsTable />
            <Footer position={'absolute'} />
        </div>
    )
}

export default Notifications;