import React from 'react'
/* Styles */
import * as styles from "../../styles/validate_identity"

export default function Logos({children}) {

  let json = JSON.parse(localStorage.getItem("datosNodos"));

  return (
    <div className={styles.logos_container}>
      {/* <img src={LogoAlliance} alt="Logo DICIO Alliance" className={styles.logo} onClick={() => navigate('/home')}/> */}
      <div className={`circle_entrance ${styles.azteca_container}`}>
        <div className={styles.azteca_circle}>
          <img src={json.url_image} alt="Logo Banco azteca" className={styles.logo}/> 
        </div>
        <i className="bi bi-chevron-double-right animate__animated animate__fadeIn animate__delay-2s"></i>
        {children}
      </div>
    </div>
  )
}
