import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { createBrowserHistory } from "history";

import Login from './pages/Login_andres';
import Edit from './pages/edit_node';
import Tabla from './pages/tabla';
import Splash from './pages/splash';
import HomePrincipal from './pages/home_principal';
import ValidateIdentity from "./pages/validate_identity"
import Registry from "./pages/registry"
import SearchResults from "./pages/search_results"
import Search from "./pages/search"
import Notifications from './pages/notifications';

const history = createBrowserHistory();

function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={<HomePrincipal />}></Route>
      </Routes>
      <Routes>
        <Route path="/editar" element={<Edit />}></Route>
      </Routes>
      <Routes>
        <Route path="/datos" element={<Tabla />}></Route>
      </Routes>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
      </Routes>
      <Routes> 
        <Route path="/" element={<Splash />}></Route>
      </Routes>
      <Routes> 
        <Route path="/validate-identity" element={<ValidateIdentity />}></Route>
      </Routes>
      <Routes> 
        <Route path="/registry" element={<Registry />}></Route>
      </Routes>
      <Routes> 
        <Route path="/search-results" element={<SearchResults />}></Route>
      </Routes>
      <Routes> 
        <Route path="/search" element={<Search />}></Route>
      </Routes>
      <Routes> 
        <Route path="/notifications" element={<Notifications />}></Route>
      </Routes>
  </BrowserRouter>
  );
}

export default App;
