import React, { useEffect, useState, useMemo } from "react";
import Pagination from "react-js-pagination";

const PaginationComponent = ({
    total = 0,
    itemsPerPage = 10,
    currentPage = 1,
    onPageChange
}) => {
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        if (total > 0 && itemsPerPage > 0)
            setTotalPages(Math.ceil(total / itemsPerPage));
    }, [total, itemsPerPage]);

    if (totalPages === 0) return null;

    return (
        <>
            <Pagination
            activePage={currentPage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            onChange={e => onPageChange(e)}
            />
        </>
    );
};

export default PaginationComponent;
