import React,{useEffect} from "react";
/* Componentes */
import Logos from "../components/ValidateIdentity/Logos";
import ResultsTable from "../components/Results/Results";
import Header from "../components/header";
import Footer from "../components/footer";
/* Images */
import Database from "../assets/search.svg"
/* Styles */
import * as styles from "../styles/validate_identity";

export default function SearchResults() {
  useEffect(() => {
    if(localStorage.getItem('colorNodo'))
        document.querySelector(':root').style.setProperty('--principal-color', localStorage.getItem('colorNodo')); 
}, []);
  return (
    <div className={styles.main_container} style={{width:"98%", maxWidth:"none"}}>
      <Header route={'/home'} absolute={true} />
      <Logos>
        <div className={styles.icon_circle}>
          <img src={Database} alt="Databse icon" className={styles.logo} />
        </div>
      </Logos>
      <ResultsTable />
      <Footer position={'absolute'} />
    </div>
  );
}
