import React, { useEffect, useRef } from 'react';
import { createPortal } from "react-dom";
import * as styles from "../../styles/alerts"

export default function Alert({type, children}) {
  const refDiv = useRef(null);
  useEffect(()=>{
    if(document.querySelector("#validate_indeti_contendor_dco")){
      alinearDiv();
      window.addEventListener('resize', alinearDiv);
    }
  },[]);

  const alinearDiv = ()=>{
    let contenedorReferencia = document.querySelector("#validate_indeti_contendor_dco").getBoundingClientRect();
    let nuevoLefth = contenedorReferencia.left + contenedorReferencia.width/2;
    let nuevoWidth = contenedorReferencia.width;
    refDiv.current.style.left= nuevoLefth + "px";
    refDiv.current.style.width= nuevoWidth + "px";
  }  
return createPortal(
    <div ref={refDiv} className={`${styles.alert_colors[type]} ${styles.alert}`} role="alert">
      {children}
    </div>,
    document.getElementById("root")
  )
}
