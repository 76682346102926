import { React, useEffect } from "react";
/* hooks */
import useSearch from "../../logic/hooks/useSearch";
/* Components */
import Input from "../FormElements/Input";
import InputFile from "../FormElements/InputFile";
/* styles */
import * as styles from "../../styles/validate_identity";
import { useNavigate } from "react-router-dom";
import Alertas from "../Sharing/Alert"

export default function Form() {

  const navigate = useNavigate();
  const {
    formikSubmit,
    getFieldProps,
    touched,
    errors,
    handleFileChange,
    selfieName,
    end,
    curp,
    msjAlerta,
    loader
  } = useSearch();

  useEffect(() => {
    if (end) {
      setTimeout(() => {
        navigate('/search-results?curp=' + curp.toUpperCase())
      }, 1000);
    }
  }, [end])

  const regresarHome = () => {
    navigate('/home')
  }
  return (
    <form className={styles.form} onSubmit={formikSubmit}>
      <Input
        id="curp"
        type="text"
        label="CURP"
        error={errors.curp}
        // touched={touched.curp}
        {...getFieldProps("curp")}
      />
      <div className="d-flex gap-3 mt-5 justify-content-start container-fluid p-0">
        <button 
          type="submit" 
          className={`btn ${!getFieldProps('curp')?.value ? 'btn-secondary' : 'btn-primary'} p-2`}
          disabled={!getFieldProps('curp')?.value}
        >
          Buscar registro
        </button>
        <button type="button" onClick={regresarHome} className="btn bg-transparent">
          Cancelar
        </button>
      </div>
      {
        (msjAlerta) ? <Alertas type={"error"} children={msjAlerta}></Alertas> : ""
      }
    </form>
  );
}
