import React, { useEffect, useState } from "react";
/* Librerías */
import { Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Footer = ({logout, position}) => {
  /* Hooks */
  const [datos, setDatos] = useState({ queries: {} });
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();

  /* UseEffect's */
  useEffect(() => {
    let json = JSON.parse(localStorage.getItem("datosNodos"))
    setDatos(json)
  }, [])

  /* Funciones */
  const copiar = (value) => {
    navigator.clipboard.writeText(value)
    setShowToast(true)
  }

  const logOut = () => navigate(`/`, {replace: true})

  return (
    <footer style={{width:"100dvw"}} className={`footer_container animate__animated animate__fadeIn animate__delay-2s animate__slower ${position === 'absolute' ? 'absolute_footer' : ''}`}>
      <div className="div_row">
        <i className="bi bi-front icono_copy" onClick={(e) => { copiar(datos.address_node) }}></i>
        <p className="text_alliance"><b>Wallet: </b> &nbsp; {(datos?.address_node)?.substr(0, 4)}...{(datos?.address_node)?.substr(datos?.address_node.length - 5)} &nbsp; </p>
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
          <Toast.Body>Información copiada a tu portapapeles.</Toast.Body>
        </Toast>
      </div>
      {logout &&
        <div className={`tooltip_alliance_logout`}>
          <p className="tooltip_text_logout">Cerrar sesión<i></i></p>
          <div className="row" style={{flexWrap:"nowrap", marginRight:"0"}} onClick={logOut}>
          <i style={{marginRight:"0"}} className="bi bi-box-arrow-right icono_copy" ></i>
          <p>Salir</p>
          </div>
        </div>
      }
    </footer>
  )
}

export default Footer
