import React, { useState, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from "react-router-dom";

function App() {
  
  const timeout = 24*3600*1000;
  const navigate = useNavigate();

  const [elapsed, setElapsed] = useState(0)
  const [lastActive, setLastActive] = useState(+new Date())
  const [isIdle, setIsIdle] = useState(false)
  const [fecha, setFecha] = useState("")
  const [isTimeup, setTimeup] = useState(false)
  const [remaining, setRemaining] = useState(timeout)

  const handleOnActive = () => setIsIdle(false)
  const handleOnIdle = () => setIsIdle(true)

  const {
    reset,
    pause,
    resume,
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  })

  const handleReset = () => reset()
  const handlePause = () => pause()
  const handleResume = () => resume()

  useEffect(() => {
    setRemaining(getRemainingTime())
    setLastActive(getLastActiveTime())
    setElapsed(getElapsedTime())

    setInterval(() => {
      setRemaining(getRemainingTime())
      setLastActive(getLastActiveTime())
      setElapsed(getElapsedTime())
    }, 1000)
  }, [])

  useEffect(() => {
            const ye = new Intl.DateTimeFormat('en', { hour: '2-digit' }).format(lastActive);
            const mo = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(lastActive);
            const da = new Intl.DateTimeFormat('en', { second: '2-digit' }).format(lastActive);
            setFecha(`${ye}-${mo}-${da}`)
  }, [lastActive])

  useEffect(() => {
    const token = sessionStorage.getItem('token')
    if(!token){
        terminar()
    }
}, [elapsed])

    const terminar = () => {
        console.log("BYE");
            setTimeup(true);
            handlePause();
            setTimeout(() => {
                navigate('/');
            }, 200);
    }

  return (
    <div>
      {(isTimeup) ? 
        <div>
          "Ha expirado la sesisón"
        </div>
        :
        ""
      }
    </div>
  );
}

export default App;
