import { useFormik } from 'formik';
import { INITIAL_VALUES } from "../constants/validateIdentity"
import { ValidateIdentitySchema } from "../schemas/search";
import { useState, useContext } from 'react';
import { guardarRegistro } from "../../services/api"
/* context */
import { ProgressBarContext } from '../context/progressBarProvider'
import Alert from '../../components/Sharing/Alert';

const useRegistry = () => {
  const [selfieName, setSelfieName] = useState("");
  const [msjAlerta, setMsjAlerta] = useState("");
  const [finish, setFinish] = useState("");
  const [curp, setCurp] = useState("");
  const [load, setLoad] = useState(false);
  const { setLoader, setValueProgressBar } = useContext(ProgressBarContext);

  const validateIdentityFormik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: ValidateIdentitySchema,
    onSubmit: async (values) => {
      console.log(values)
      let curp = values.curp
      setCurp(curp)
      setLoad(true)
      setLoader(true);
      setValueProgressBar('25');
      if (curp) {
        if (curpValida(curp.toUpperCase())) {
          setTimeout(() => {
            setValueProgressBar('50');
          }, 200);
          setTimeout(() => {
            setValueProgressBar('75');
          }, 400);
          setTimeout(() => {
            setFinish(true)
            setValueProgressBar('100');
            setLoad(false)
          }, 1000);
        } else {
          setMsjAlerta("El curp no cumple con el formato, por favor de revisar")
          setLoader(false);
        }
      } else {
        setMsjAlerta("Por favor revisa que los datos esten completos")
        setLoader(false);
        console.log("error en el curp");
      }
      setTimeout(() => {
        setMsjAlerta("")
      }, 3000);
    },
  });

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    setSelfieName(event.currentTarget.files[0].name)
    validateIdentityFormik.setFieldValue("selfie", file);
  };

  const curpValida = (curpInput) => {
    let curp = curpInput.toUpperCase()
    let re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      validado = curp.match(re);

    if (!validado)  //Coincide con el formato general?
      return false;

    //Validar que coincida el dígito verificador

    function digitoVerificador(curp17) {
      //Fuente https://consultas.curp.gob.mx/CurpSP/
      let diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
        lngSuma = 0.0,
        lngDigito = 0.0;
      for (let i = 0; i < 17; i++)
        lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
      lngDigito = 10 - lngSuma % 10;
      if (lngDigito == 10) return 0;
      return lngDigito;
    }

    if (validado[2] != digitoVerificador(validado[1]))
      return false;

    return true; //Validado
  }

  return {
    formikSubmit: validateIdentityFormik.handleSubmit,
    getFieldProps: validateIdentityFormik.getFieldProps,
    touched: validateIdentityFormik.touched,
    errors: validateIdentityFormik.errors,
    handleFileChange,
    selfieName,
    end: finish,
    curp: curp,
    msjAlerta: msjAlerta,
    loader: load
  }
};

export default useRegistry;