import axios from 'axios';

const api_key = "8rQjNygyyhMAK5p6Ya2jBbkoIbQL9IM7"

const hostBC = "http://169.57.44.49:8000"

const endpoints = {
    saveRegistry : `/v1/dev_webhook_workflows/webhook/upload_reference_rekognition`,
    validateRegistry: `/v1/dev_webhook_workflows/webhook/initial_main_search_rekognition`,
    getStatus: `/v1/dev_webhook_workflows/webhook/status_transaction?transaccion=`,
    getList: `/v1/dev_webhook_workflows/webhook/transactions?`,
    getImageNode: `/v1/dev_webhook_workflows/webhook/view_reference_image?curp=`,
    searchCurp: `/v1/dev_webhook_workflows/webhook/curp_transactions?curp=`,
    getTransactions: `/v1/dev_webhook_workflows/webhook/curp_transactions?curp=`,
    detailsNode: `/v1/dev_webhook_workflows/webhook/node`,
    getDataBC: `${hostBC}/v1/find_by_id`,
    getImageConsult: `/v1/dev_webhook_workflows/webhook/view_query_image?image_name=`,//
    getStatusC: `/v1/dev_webhook_workflows/webhook/status_consortium`,//
    login: `/v1/dev_login/webhook/login_alliance`,//
    alerts: `/v1/dev_webhook_workflows/webhook/alerts`,
    markAlert: `/v1/dev_webhook_workflows/webhook/alert_read`,
};


const encabezadosIMG = {
    'Content-Type': 'multipart/form-data',
    'apikey': api_key
}

const encabezados = {
    'Content-Type': 'application/json',
    'apikey': api_key
}

//const hostNodes = ["","https://nodehw.qadicio.net:8443", "https://nodeaws.qadicio.net:8443","https://nodegcp.qadicio.net:8443"]
const hostNodes = ["",process.env.REACT_APP_NODE1, process.env.REACT_APP_NODE2,process.env.REACT_APP_NODE3]

/*Sesion*/
export function initSesion(username, password) {
    return axios({
        method: 'post',
        url: hostNodes[localStorage.getItem('nodo')] + endpoints.login,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            username: username,
            password: password
        }
    })
}
/* Registro */
export function guardarRegistro(curpValue, foto) {

    let formato = foto.type
    let nombre = "frame_"+ new Date().getTime()+ "." +formato.split("/")[1]
    console.log("nombre",nombre);
    var formdata = new FormData()
    formdata.append("curp", curpValue);
    formdata.append("imageReference", foto, nombre);
    return axios({
        url: hostNodes[localStorage.getItem('nodo')] + endpoints.saveRegistry,
        method: 'POST',
        headers: encabezadosIMG,
        data: formdata
    })
}
/* Validación */
export function validarRegistro(curpValue, foto) {
    let formato = foto.type
    let nombre = "frame_"+ new Date().getTime()+ "." +formato.split("/")[1]
    console.log("nombre",nombre);
    var formdata = new FormData();
    formdata.append("curp", curpValue);
    formdata.append("imageQuery", foto, nombre);
    formdata.append("nodes_for_vote", "3");
    formdata.append("flow", "withRenapo");
    return axios({
        url: hostNodes[localStorage.getItem('nodo')] + endpoints.validateRegistry,
        method: 'POST',
        headers: encabezadosIMG,
        data: formdata
    })
}

export function obtenerStatus(idTrx) {
    return axios({
        url: hostNodes[localStorage.getItem('nodo')] + endpoints.getStatus + idTrx,
        method: 'GET',
        headers: encabezados,
    })
}

export function obtenerLista (pagina) {
    return axios({
        url: endpoints.getList + "perPage=10&page="+pagina,
        method: 'GET',
        headers: encabezados,
    })
}

export function verImagenNodo (curp) {
    return axios({
        url: hostNodes[localStorage.getItem('nodo')] + endpoints.getImageNode + curp,
        method: 'GET',
        headers: encabezados,
    })
}

export function obtenerImagenConsulta (trx, nombreIMG) {
    return axios({
        url: hostNodes[localStorage.getItem('nodo')] + endpoints.getImageConsult + nombreIMG + "&transaccion=" + trx,
        method: 'GET',
        headers: encabezadosIMG,
    })
}

export function busquedaCurp (curp, pagina) {
    return axios({
        url: hostNodes[localStorage.getItem('nodo')] + endpoints.searchCurp + curp + "&perPage=10&page="+pagina,
        method: 'GET',
        headers: encabezados,
    })
}

export function obtenerTransacciones (items, fini, ffin, curp) {
    //console.log("obtenerTransacciones", items, fini, ffin, curp );
    let link = hostNodes[localStorage.getItem('nodo')] + endpoints.getTransactions + "&perPage="+items+"&page=1"
    if(fini !== 0 && ffin !== 0){
        let last = new Date(ffin.setHours(23,59,59))
        console.log("last",last);
        link = hostNodes[localStorage.getItem('nodo')] + endpoints.searchCurp + curp + "&perPage="+items+"&page=1&start_date="+fini.toISOString()+"&end_date="+last.toISOString() //&start_date=2022-08-10T13:00:00&end_date=2022-08-10T14:59:59'
    }else if(curp){
        link = hostNodes[localStorage.getItem('nodo')] + endpoints.searchCurp + curp  + "&perPage="+items+"&page=1"
    }
    
    return axios({
        url: link,
        method: 'GET',
        headers: encabezados,
    })
}

export function obtenerTransaccionesPaginado (items, fini, ffin, curp, page) {
    console.log("obtenerTransaccionesPaginado", items, fini, ffin, curp, page);
    let link = hostNodes[localStorage.getItem('nodo')] + endpoints.getTransactions + "&perPage="+items+"&page="+page
    if(fini && ffin) {
        if(fini !== 0 && ffin !== 0){
            link = hostNodes[localStorage.getItem('nodo')] + endpoints.searchCurp + curp + "&perPage="+items+"                                                                           &start_date="+fini.toISOString()+"&end_date="+ffin.toISOString()+"&page="+page
        }else if(curp){
            link = hostNodes[localStorage.getItem('nodo')] + endpoints.searchCurp + curp  + "&perPage="+items+"&page="+page
        }
    }else if(curp){
        link = hostNodes[localStorage.getItem('nodo')] + endpoints.searchCurp + curp  + "&perPage="+items+"&page="+page
    }
    console.log(link);
    return axios({
        url: link,
        method: 'GET',
        headers: encabezados,
    })
}

export function detallesNodo () {
    return axios({
        url: hostNodes[localStorage.getItem('nodo')] + endpoints.detailsNode,
        method: 'GET',
        headers: encabezados,
    })
}

export function statusConsorcio () {
    return axios({
        url: hostNodes[localStorage.getItem('nodo')] + endpoints.getStatusC,
        method: 'GET',
        headers: encabezados,
    })
}

// Notificaciones
/* Obtiene las alertas nuevas */
export function getAlerts () {
    return axios({
        url: hostNodes[localStorage.getItem('nodo')] + endpoints.alerts + '?perPage=10&page=1&?type=all',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'apikey': api_key
        }
    })
}

/* Obtiene todas las alertas */
export function getAlertsData (page) {
    return axios({
        url: hostNodes[localStorage.getItem('nodo')] + endpoints.alerts + `?perPage=10&page=${page}&type=all`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'apikey': api_key,
            'host': hostNodes[localStorage.getItem('nodo')]
        }
    })
}

/* Marca como leída las alertas */

export function markAlertReadedById (idAlert) {
    console.warn(`✨ --------------------------------------------------------------✨`)
    console.warn(`✨ ~ file: api.js:209 ~ markAlertReadedById ~ idAlert:`, idAlert)
    console.warn(`✨ --------------------------------------------------------------✨`)
    return axios({
        url: hostNodes[localStorage.getItem('nodo')] + endpoints.markAlert,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'apikey': api_key,
            'host': hostNodes[localStorage.getItem('nodo')]
        },
        data: {
            id: idAlert
        }
    })
}