import { css } from "@emotion/css";

export const label = ({
  focused,
  inputError,
  inputValid,
}) => css`
  position: absolute;
  left: ${!focused ? `.75rem` : `.3rem`};
  top: ${!focused ? `50%` : `0%`};
  transition: all 0.3s ease-in-out;
  transform: ${!focused ? `translateY(-50%)` : `translateY(-110%)`};
  pointer-events: none;
  z-index: 1;
  ${focused ? `font-size: 14px; font-weight: 400;` : `font-size: 20px; font-weight: 400;`}
  ${inputError && `color: #DC3545;`}
`;
