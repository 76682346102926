import React,{useEffect} from 'react'
/* Componentes */
import Logos from "../components/ValidateIdentity/Logos"
import FormCard from "../components/Registry/FormCard"
import Header from '../components/header'
import Footer from '../components/footer'
/* Styles */
import * as styles from "../styles/validate_identity"
/* Provider */
import { ProgressBarProvider } from '../logic/context/progressBarProvider'
/* Images */
import registerIcon from "../assets/register.svg"

export default function Registry() {
  useEffect(() => {
    if(localStorage.getItem('colorNodo'))
        document.querySelector(':root').style.setProperty('--principal-color', localStorage.getItem('colorNodo')); 
  }, []);
  return (
    <>
    <div className={styles.main_container}>
      <Header route={'/home'} absolute={true} />
      <Logos>
        <div className={`anim_icon_topleft_center ${styles.user_circle}`}>
          <img src={registerIcon} alt="Register icon" className={`${styles.logo}`} />
        </div>
      </Logos>
      <ProgressBarProvider>
        <FormCard />
      </ProgressBarProvider>
      
    </div>
    <div>
    <Footer position={'absolute'} logout={true} />
    </div>
    </>
  )
}
