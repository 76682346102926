import React, { useEffect, useState } from "react";
/* Librerías */
import { useNavigate } from "react-router-dom";
import { Row, Col, Spinner } from "react-bootstrap";
import 'react-datepicker/dist/react-datepicker.css';
/* Servicios */
import { detallesNodo, getAlerts, statusConsorcio } from "../services/api";
/* Componentes */
import Header from "../components/header";
import Footer from '../components/footer';

const HomePrincipal = () => {
    const navigate = useNavigate();

    /* Hooks */
    const [loading, setLoading] = useState(false);
    const [datos, setDatos] = useState({ queries: {} })
    const [ndisponible, setNdisponible] = useState("")
    const [nquery, setNquery] = useState("")
    const [nrecord, setNrecord] = useState("")
    const [msjAlerta, setMsjAlerta] = useState("")
    const [countNotifications, setCountNotifications] = useState(false)

    useEffect(() => {
        if(localStorage.getItem('colorNodo'))
            document.querySelector(':root').style.setProperty('--principal-color', localStorage.getItem('colorNodo')); 
    }, []);
    
    /* UserEffect's */
    useEffect(() => {
        setLoading(true)
        solicitarDatosDashboard();
        let intervalo = setInterval(()=>{
            solicitarDatosDashboard();
            getNotificationsAlert();
        },10000);
        return() =>{
            clearInterval(intervalo);
        }
    }, []);

    /* Funciones */
    const solicitarDatosDashboard =()=>{
        statusConsorcio().then((response) => {
            console.log("response:", response.data);
            setNdisponible(response.data.avaliable_nodes)
            setNquery(response.data.queries)
            setNrecord(response.data.records)
        }).catch((e) => {
            console.log("e", e);
            setMsjAlerta("Ha ocurrido un error, por favor revisa tu conexión a internet")
        }).finally(() => {
            setLoading(false)
        })

        detallesNodo().then((response) => {
            console.log("response:", response.data);
            let n = response.data.id_node.substr(response.data.id_node.length - 1)
            //localStorage.setItem("nodo", n);
            setDatos(response.data)
            //console.log("response.data:",Object.keys(response.data).length );
            localStorage.setItem("datosNodos", JSON.stringify(response.data));
        }).catch((e) => {
            console.log("e", e);
            /*
            if(e.response.data.error.description){
                setMsjAlerta(e.response.data.error.description)
            }else{
                setMsjAlerta("Ha ocurrido un error, por favor revisa tu conexión a internet")
            }
            */
            setMsjAlerta("Ha ocurrido un error, por favor revisa tu conexión a internet")
        }).finally(() => {
            setLoading(false)
        })
    }

    const getNotificationsAlert = async () => {
        try {
            let responseAlerts = await getAlerts();
            const { data, status } = responseAlerts;
            if ( status === 200 && data.alerts?.length > 0 ) {
                setCountNotifications(true)
            } else {
                setCountNotifications(false)
            }
        } catch (err) {
            setCountNotifications(false)
            console.warn(`✨ -------------------------------------------------------------------✨`)
            console.warn(`✨ ~ file: home_principal.jsx:77 ~ getNotificationsAlert ~ err:`, err)
            console.warn(`✨ -------------------------------------------------------------------✨`)
        }
    }

    const agregarRegistro = () => navigate("/registry")
    const validarID = () => navigate("/validate-identity")
    const bucarCurp = () => navigate("/search")
    const irLista = () => navigate('/search-results')

    /* Render */
    return (
        <div className="alliance_purple_container vh-100">
            <div className="resume_container circle_shapes">
                <Header 
                    notifications={countNotifications}
                    iconNotifications={true}
                />
                <div className="body_data_container">
                    <div className="options_container">
                        <div className="tooltip_alliance animate__animated animate__zoomIn animate__delay-3s animate__faster" >
                            <p className="tooltip_text">Agregar registro<i></i></p>
                            <div>
                                <img src="../images/dicio/agregar_registro.svg" alt="Agregar registro" onClick={(e) => agregarRegistro()} />
                            </div>
                        </div>
                        <div className="tooltip_alliance animate__animated animate__zoomIn animate__delay-4s animate__faster">
                            <p className="tooltip_text">Validar ID<i></i></p>
                            <img src="../images/dicio/search_user.svg" alt="Validar ID" onClick={(e) => validarID()} />
                        </div>
                        <div className="tooltip_alliance animate__animated animate__zoomIn animate__delay-5s animate__faster">
                            <p className="tooltip_text">Buscar proceso<i></i></p>
                            <img src="../images/dicio/search_database.svg" alt="Buscar proceso" onClick={(e) => bucarCurp()} />
                        </div>
                    </div>
                    <div className="circle_data_container">
                        <div className="shape_circle animate__animated animate__zoomIn animate__faster">
                            <img src={datos.url_image} className="animate__animated animate__fadeInUp logo_cliente_circle" height="30" alt="" style={{ float: "left" }} />
                            <div style={{position:"relative", top:"35px"}}>
                                <span style={{marginRight:"50px"}} className="animate__animated animate__fadeInUp animate__delay-1s animate__fast">
                                    <img className="icon_circle_data" src="../images/dicio/check.svg" alt="check" />
                                    <Col style={{marginLeft:"10px"}}>
                                        <p style={{display: "flex", justifyContent: "center"}} className="number_circle_data">{datos ? datos.queries.completed : <Spinner animation="border" />}</p>
                                        <div style={{ flexDirection: 'column', display: 'flex', justifyContent: "center", alignItems:"center" }}>
                                            <small>Validaciones en el nodo</small>
                                            <b onClick={irLista}>{'Ver detalle >>'}</b>
                                        </div>
                                    </Col>
                                </span>
                                <span style={{marginRight:"50px"}} className="animate__animated animate__fadeInUp animate__delay-1s animate__slow">
                                    <img style={{position:"relative", left:"40px"}} className="icon_circle_data" src="../images/dicio/menu.svg" alt="check" />
                                    <Col style={{marginLeft:"30px"}}>
                                        <p className="number_circle_data" style={{ fontSize: '35px', fontWeight: 'bold', display: 'flex', justifyContent: "center"}}>{datos ? datos.records : <Spinner animation="border" />}</p>
                                        <div style={{ flexDirection: 'column', display: 'flex', justifyContent: "center", alignItems:"center" }}>
                                            <small style={{ fontSize: '14px', fontWeight: 'bolder' }}>Registros en el nodo</small>
                                        </div>
                                    </Col>
                                </span>
                            </div>
                            <span className="animate__animated animate__fadeInUp animate__delay-2s animate__fast" style={{ justifyContent: 'center', flexDirection: 'row', marginTop: '80px' }}>
                                <div className="green-stats"></div>
                                <p className="text_gray_alliance">Nodo Activo</p>
                            </span>
                        </div>
                    </div>
                    <div className="animate__animated animate__fadeIn animate__delay-2s animate__slower info_container">
                        <div style={{ justifyContent: 'center', flexDirection: 'row', width: '100%', marginBottom: '10px' }}>
                            <div className="green-stats"></div>
                            <p className="text_alliance"><b>{ndisponible ? ndisponible : <Spinner className="spinner_small" animation="border" />}</b> nodos</p>
                            <small className="text_alliance margin_small">En la red</small>
                        </div>
                        <div style={{ justifyContent: 'center', flexDirection: 'row', width: '100%', marginBottom: '10px' }}>
                            <div className="purple-stats"></div>
                            <p className="text_alliance"><b>{nrecord ? nrecord : <Spinner className="spinner_small" animation="border" />}</b> registros</p>
                            <small className="text_alliance margin_small">En la red</small>
                        </div>
                        <div style={{ justifyContent: 'center', flexDirection: 'row', width: '100%', marginBottom: '10px' }}>
                            <div className="blue-stats"></div>
                            <p className="text_alliance"><b>{nquery ? nquery : <Spinner className="spinner_small" animation="border" />}</b> validaciones</p>
                            <small className="text_alliance margin_small">En la red</small>
                        </div>
                    </div>
                </div>
                {datos?.address_node && 
                    <Footer 
                        logout={true}
                        navigate={navigate}
                    />
                }
            </div>
        </div>
    )
}

export default HomePrincipal