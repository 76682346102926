import React, {useEffect} from 'react'
/* Componentes */
import Logos from "../components/ValidateIdentity/Logos"
import FormCard from "../components/ValidateIdentity/FormCard"
import Header from '../components/header'
import Footer from '../components/footer'
/* Images */
/* Styles */
import * as styles from "../styles/validate_identity"
/* Provider */
import { ProgressBarProvider } from '../logic/context/progressBarProvider'

export default function ValidateIdentity() {
  useEffect(() => {
    if(localStorage.getItem('colorNodo'))
        document.querySelector(':root').style.setProperty('--principal-color', localStorage.getItem('colorNodo')); 
  }, []);
  return (
    <div className={styles.main_container}>
      <Header route={'/home'} absolute={true} />
      <Logos>
        <div className={styles.user_circle}>
          <img style={{maxHeight:"none"}} src={"/images/dicio/search_user.svg"} alt="User icon" className={styles.logo} />
        </div>
      </Logos>
      <ProgressBarProvider>
        <FormCard />
      </ProgressBarProvider>
      <Footer position={'absolute'} />
    </div>
  )
}
