import { css, cx } from "@emotion/css";
/* Mixins */ 
import { flex, content, grid } from "../logic/utils/styles/mixins";
/* Images */ 
import Search from "../assets/search.webp";
import Arrows from "../assets/double_arrows.webp";

export const main_container = cx(
  content({
    width: "1440px",
    padding: "0px"
  }),
  grid({
    template_cols: "30% 70%",
    template_rows: "1fr"
  }),
  css`
    min-height: 100vh;
    overflow: hidden;
  `
);

export const logos_container = cx(
  flex({
    direction: "column",
    justify: "start",
  }),
  css`
    padding: 50px 0;
  `
);

export const azteca_container = cx(
  flex({
    justify: "end",
  }),
  css`
    width: 100%;
    height: 100%;
    position: relative;
  `
);

export const user_circle = cx(
  flex({}),
  css`
    max-width: 80px;
    min-width: 80px;
    max-height: 80px;
    min-height: 80px;
    position: relative;
    background: #FFF;
    border-radius: 50%;
    padding: 20px;
    &::after{
      content: "";
      display: block;
      position: absolute;
      width: 15px;
      height: 15px;
      top: 18px;
      right: 18px;
    }
    box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.21);
  `
);

export const icon_circle = cx(
  flex({}),
  css`
    max-width: 80px;
    min-width: 80px;
    max-height: 80px;
    min-height: 80px;
    position: relative;
    background: #FFF;
    border-radius: 50%;
    padding: 20px;
    &::after{
      content: "";
      display: block;
      position: absolute;
      width: 30px;
      height: 30px;
      top: 50%;
      right: 0;
      
      transform: translate(200%, -50%) rotate(-90deg);
      opacity: .7;
    }
    box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.21);
  `
);

export const circle = cx(
  flex({}),
  css`
    max-width: 80px;
    min-width: 80px;
    max-height: 80px;
    min-height: 80px;
    position: relative;
    background: #FFF;
    border-radius: 50%;
    padding: 20px;
    &::after{
      content: "";
      display: block;
      position: absolute;
      width: 15px;
      height: 15px;
      top: 18px;
      right: 18px;
    }
  `
);

export const azteca_circle = cx(
  flex({
    justify: "center",
    align: "start",
  }),
  css`
    position: absolute;
    width: 550px;
    height: 550px;
    border-radius: 50%;
    padding: 36%;
    top: 50%;
    left: 50%;
    transform:  translate(-100%, -50%) rotate(90deg);
    background: #FFF;
    margin-left: 16%;
  `
);

export const logo = cx(
  css`
    width: 240px;
    height: auto;
    max-height: 47px
  `
);

export const logo2 = cx(
  css`
    width: 265%;
    height: auto;
  `
);

export const form_card = cx(
  content({
    width: "70%",
    padding: "40px"
  }),
  flex({
    align: "start"
  }),
  css`
    background: #FFF;
    max-height: 60%;
    min-height: 60%;
    align-self: center;
    justify-self: center;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    margin-left: -10%;
    &::after{
      content: "";
      display: block;
      position: absolute;
      width: 30px;
      height: 30px;
      top: 52%;
      left: 0;
      
      transform: translate(-200%, -50%) rotate(-90deg);
      opacity: .7;
    }
  `
);

export const form_card_container = cx(
  flex({
    direction: "column",
    align: "start",
    justify: "start",
    gap: "20px"
  }),
  css`
    width: 100%; 
    max-width: 320px;
  `
);

export const form = cx(
  flex({
    direction: "column"
  }),
  css`
    width: 100%; 
  `
);

export const title = cx(
  css`
  font-size: 26px;
    color: var(--accent-color);
    font-weight: bold;
  `
);

export const text = cx(
  css`
    margin: 0;
    font-size: 20px;
  `
);
export const contentHeaderSalir=cx(
  css`
  position: absolute;
  right: 0;
  top: 0;
  margin: 20px;
  z-index: 2;
  & button{
    border: none;
    background: none;
  }
  `
)
