import React, { useCallback, useState, useEffect } from 'react';
/* Styles */
import * as styles from "../../styles/search_results";
/* Componentes */
import { TableHeader } from '../DataTable';
import NotificationSmall from './NotificationSmall';
import NotificationComplete from './NotificationComplete';
import TablePagination from "../DataTable/Pagination/index";
import { tableNotifications } from '../../logic/data/search_result/table';
/* Servicios */
import { getAlertsData, markAlertReadedById } from '../../services/api';
import Loader from '../loader';

const DataTable = () => {
    /* Hooks */
    const [loading, setLoading] = useState(false);
    const [msjError, setMsjError] = useState('');
    const [showDetails, setShowDetails] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [showNotificationData, setShowNotificationData] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [currentAlert, setCurrentAlert] = useState({});
    const [totalItems, setTotalItems] = useState(0);

    /* Callbacks */
    const getNotifications = useCallback(async () => {
        setLoading(true)
        setNotifications([])
        setTotalItems(0)
        try {
            let responseAlerts = await getAlertsData(currentPage);
            const { data, status } = responseAlerts;
            if (status === 200) {
                
                setNotifications(data.alerts)
                setTotalItems(data.total_records)
            } else {
                setMsjError('No se lograron obtener las notificaciones, intenta nuevamente por favor.')
            }
            
            
        } catch (err) {
            setMsjError('Revisa tu conexión a internet e intenta nuevamente')
            console.warn(`✨ ----------------------------------------------------------✨`)
            console.warn(`✨ ~ file: DataTable.jsx:119 ~ getNotifications ~ err:`, err)
            console.warn(`✨ ----------------------------------------------------------✨`)
        } finally {
             setLoading(false);
        }
    }, [])

    /* UseEffect's */
    // useEffect(() => {
    //     // Consulta las alertas para llenar la tabla
    //     getNotifications()
    // }, [getNotifications])

    useEffect(() => {
        setTimeout(()=>{
            getNotifications();
        },5000)
    }, [currentPage])

    useEffect(() => {
        console.log('CURRENT ALERT CHANGE ', currentAlert);
        if (Object.entries(currentAlert).length > 0) {
            setShowDetails(true)
            setShowNotificationData(true)
        } else {
            setTimeout(() => {
                setShowDetails(false)
            }, 1000);
        }
    }, [currentAlert])

    /* Funciones */
    const handleCloseNotification = () => {
        setCurrentAlert({})
    }

    const markNotification = async (id) => {
        try {
            let responseAlert = await markAlertReadedById(id);
            const { status, data } = responseAlert;
            console.warn(`✨ -----------------------------------------------------------✨`)
            console.warn(`✨ ~ file: DataTable.jsx:70 ~ markNotification ~ data:`, data)
            console.warn(`✨ -----------------------------------------------------------✨`)
        } catch (err) {
            console.warn(`✨ ---------------------------------------------------------✨`)
            console.warn(`✨ ~ file: DataTable.jsx:62 ~ markNotification ~ err:`, err)
            console.warn(`✨ ---------------------------------------------------------✨`)
        }
    }

    /* Render */
    return (
        <div
            className={`animate__animated animate__fadeInUp animate__delay-2s animate__slow container-fluid d-flex flex-column justify-content-start bg-white p-0 ${styles.table_notifications_container}`}
            style={{ overflowY: 'scroll' }}
        >
            <TableHeader
                headers={tableNotifications.headers}
                onSorting={(data) => console.log(data)}
            />

            <tbody className={`body_table_animation`}>
                {
                    notifications.map(
                        (
                            element,
                            index
                        ) => (
                            <tr key={index} className=''>
                                <td>{element.id}</td>
                                <td>{element.description}</td>
                                <td>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn_detail"
                                        onClick={(e) => {
                                            setCurrentAlert(element)
                                            markNotification(element.id)
                                        }}
                                    >
                                        Ver detalle
                                    </button>
                                </td>
                            </tr>
                        ))}
            </tbody>

            <TablePagination
                total={totalItems}
                itemsPerPage={10}
                currentPage={currentPage}
                className="justify-content-center animate__animated animate__fadeInUp animate__delay-5s animate__slow "
                onPageChange={page => { setCurrentPage(page); }}
            />
            {showDetails &&
                <NotificationComplete
                    data={currentAlert}
                    showNotification={showNotificationData}
                    setShowNotification={setShowNotificationData}
                    handleCloseNotification={handleCloseNotification}
                />
            }
            {loading && 
                <Loader />
            }
        </div>
    )
}

export default DataTable;